import request from '@/utils/request'

const api = {
  getShopCartList: 'order/getShopCartList',
  saveShopCart: 'order/saveShopCart',
  addShopCart: 'order/addShopCart',
  rmShopCart: 'order/rmShopCart',
  getConfirmList: 'order/getConfirmList',
  getConfirm: 'order/getConfirm',
  saveOrder: 'order/saveOrder',
  payOrder: 'order/payOrder',
  receipt: 'order/receipt',
  comment: 'order/Comment',
  orderComment: 'order/orderComment',
  refund: 'order/Refund',
  addShopCartAll: 'order/addShopCartAll'
}
export default {
  addShopCartAll(parameter) {
    return request({
      url: api.addShopCartAll,
      method: 'post',
      data: parameter
    })
  },
  orderComment(parameter) {
    return request({
      url: api.orderComment,
      method: 'post',
      data: parameter
    })
  },
  getShopCartList(parameter) {
    return request({
      url: api.getShopCartList,
      method: 'post',
      data: parameter
    })
  },
  refund(parameter) {
    return request({
      url: api.refund,
      method: 'post',
      data: parameter
    })
  },
  addShopCart(parameter) {
    return request({
      url: api.addShopCart,
      method: 'post',
      data: parameter
    })
  },
  saveShopCart(parameter) {
    return request({
      url: api.saveShopCart,
      method: 'post',
      data: parameter
    })
  },
  rmShopCart(parameter) {
    return request({
      url: api.rmShopCart,
      method: 'post',
      data: parameter
    })
  },

  getConfirmList(parameter) {
    return request({
      url: api.getConfirmList,
      method: 'post',
      data: parameter
    })
  },

  getConfirm(parameter) {
    return request({
      url: api.getConfirm,
      method: 'post',
      data: parameter
    })
  },

  saveOrder(parameter) {
    return request({
      url: api.saveOrder,
      method: 'post',
      data: parameter
    })
  },

  payOrder(parameter) {
    return request({
      url: api.payOrder,
      method: 'post',
      data: parameter
    })
  },
  receipt(parameter) {
    return request({
      url: api.receipt,
      method: 'post',
      data: parameter
    })
  },
  comment(parameter) {
    return request({
      url: api.comment,
      method: 'post',
      data: parameter
    })
  }
}
