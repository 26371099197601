<template>
  <div class="faas-container" id="faas-container">
    <div id="root">
      <div class="home--new-home--UXKZmgj" data-tticheck="main">
        <!-- 顶部分类 -->
        <div id="homeTop" :class="['group-floor', 'head-zw', hasShowBg ? 'show' : '']" style="height: 50px">
          <div data-spc="tab" data-spm="tab" class="pc2023-header-tab--container---84XTBD">
            <div class="row-container--root--33oSY1n">
              <div class="pc2023-header-tab--tabs-main--2EOR-kL">
                <!-- 分类 -->
                <CategoryList5></CategoryList5>
              </div>
            </div>
          </div>
        </div>

        <!-- banner -->
        <div class="group-floor" data-component-id="8942562" data-floor-name="pc-sale-subject-banner" style="position: relative">
          <!-- 占位 -->
          <div :class="['mx-zw', hasShowBg ? 'hide' : '']"></div>
          <div data-spm="sale_618euro_banner">
            <div id="pc-sale-subject" class="row-container--root--33oSY1n pc-sale-subject--bigWrap--3sduTjr mx-bg" style="">
              <a href="javascript:" class="pc-sale-subject--bigLink--3PJ1w6n" target="_blank"></a>
              <div class="pc-sale-subject--container--nzYVj7o">
                <!-- 左侧 -->
                <a href="javascript:" class="pc-sale-subject--leftLink--3n2Gted" style="height: 100%">
                  <div class="pc-sale-subject--left--1yPb4gl" style="height: 100%">
                    <scroll-top></scroll-top>
                  </div>
                </a>

                <!-- 轮播图 -->
                <BannerVue></BannerVue>
              </div>
            </div>
          </div>
          <div class="bot-zw"></div>
        </div>

        <!-- 宣传语 -->
        <div class="common-floor" style="padding-top: 32px; padding-bottom: 0" data-component-id="7589501" data-floor-name="us-site-info">
          <div class="row-container--root--33oSY1n">
            <div class="us-site-info--box--eTHab0Z">
              <div class="us-site-info--item--EC92aVd us-site-info--item2--3PGYkuZ">
                <img class="us-site-info--icon--3mFiXxS" src="../../static/css5/imgs/54x54.png" alt="" />
                <span class="us-site-info--title--3OKYoWm">{{ $t('_xbb._sbbt1') }}</span
                ><span class="us-site-info--subTitle--2ynl5Ka">{{ $t('_xbb._sbbt2') }}</span>
              </div>
              <div class="us-site-info--item--EC92aVd us-site-info--item2--3PGYkuZ">
                <img class="us-site-info--icon--3mFiXxS" src="../../static/css5/imgs/return.png" alt="" />
                <span class="us-site-info--title--3OKYoWm">{{ $t('_xbb._sbbt3') }}</span
                ><span class="us-site-info--subTitle--2ynl5Ka">{{ $t('_xbb._sbbt4') }}</span>
              </div>
            </div>
          </div>
        </div>

        <!-- 置顶商品 -->
        <TopGoods></TopGoods>

        <!-- 活动板块 -->
        <div class="row-container--root--33oSY1n" style="margin-top: 30px">
          <div style="border-radius: 10px; overflow: hidden">
            <router-link :to="{ name: 'halloween' }">
              <img src="../../static/css5/halloween/banner2.gif" alt="" />
            </router-link>
          </div>
        </div>
        <!-- 活动板块 -->
        <ActivityVue></ActivityVue>

        <!-- 轮播商家 -->
        <TopStore></TopStore>

        <!-- 更多商品 -->
        <!-- <IndexGoods></IndexGoods> -->
        <div class="common-floor" :infinite-scroll-disabled="scrollDisabled" infinite-scroll-watch-disabled="scrollDisabled" v-infinite-scroll="onLoadMore" :infinite-scroll-distance="20">
          <div class="row-container--root--33oSY1n">
            <div data-spm="moretolove" class="pc2023-more-to-love--motolove-warp--3jVaS6A">
              <div class="pc2023-more-to-love--motolove-title--1R4lXge">
                <div class="pc2023-more-to-love--title-text--1hXTLae">{{ $t('_xbb._gdsp') }}</div>
              </div>
              <div class="_1wVX7" data-spm="pcJustForYou" style="margin-left: -8px; margin-right: -8px">
                <!-- 商品列表 -->
                <div v-for="(item, i) in goodsData" :key="i" class="_2FypS" style="max-width: 16.6667%; padding-left: 8px; padding-right: 8px; flex-basis: 16.6667%">
                  <div class="_1Hxqh">
                    <div class="SeJ8l _1Kdtt card--out-wrapper">
                      <router-link class="_1UZxx" :to="{ name: 'detail', query: { id: item.id } }" replace>
                        <div class="_2bIiW _2xJR2" style="">
                          <img class="_1IH3l product-img" :src="item.img" :alt="item.name" aria-hidden="true" />
                          <div class="_8Ut19"></div>
                          <!-- <div class="darm7 _1kiOX">
                            <div class="_1E-TZ"><span class="HQGC-"></span></div>
                          </div> -->
                        </div>
                        <div class="_11nFI">
                          <div title="" class="G7dOC" style="margin-top: 8px">
                            <h3 class="nXeOv">{{ item.name }}</h3>
                          </div>
                          <div class="_3TqP9">
                            <div class="t9_CA">
                              <div class="Ktfxu" v-for="(xv, xi) in parseInt(item.star)" :key="xi">
                                <div class="_2E4Wz" dir="ltr" style="width: 10px"></div>
                                <img
                                  class="EYoOU"
                                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAeCAYAAAE6DSy/AAAABGdBTUEAALGPC/xhBQAAAGxlWElmTU0AKgAAAAgABAEaAAUAAAABAAAAPgEbAAUAAAABAAAARgEoAAMAAAABAAIAAIdpAAQAAAABAAAATgAAAAAAAACQAAAAAQAAAJAAAAABAAKgAgAEAAAAAQAAACCgAwAEAAAAAQAAAB4AAAAA2L2NEgAAAAlwSFlzAAAWJQAAFiUBSVIk8AAAArdJREFUSA2tl81qFFEQhSeJEDEYQYT4g+DCEFwoPoQbA7pwl1UIeQ5fIA/gIyQEJDggKG7VbESEIEQ0hmyjO0FBicTvdN+6Vt++PdOdTkFN1a0659Ttnv6ZGRz/t6tKB2F9OMBiQQuzSUssThQwWyn6gnJRLjjAmxrCxgotWzGZclnu67qTqKcRWW8V6pV9G5j4PuJdUelGsj6uHOgEJqZApuBHPLQicdvlY1Ivl0LVK0YQ13LNotakQH1XvbhJ8kUVZEHtSVQoy/VPG0tntd4tK+mXGXFGtkhjOTZdIoFNA3WNcM/aDqa7koVHYGgCtqm5NkKAd4wQz3IgXmkjAOZ2xJmSYiyGhNIS/hmvDGL9GC8sPQSrW7xG8tIWuThKYNV2lCNarVHAyIqAV4yQxiaBZS8QRFJusc4JvE3JQeABjMNURQKXcoQ2NbgbbXCNGARuaQfDRsSIBrxpPD7RX4/A1lrwZkWW+ZO4U0NmCnDmCmb48AK1SznDF36tr8DdJoHd3MRczQv4u2zdgwFN4roTl3w9zb3AX2tC0ttH63l8nbVs0fo+Vl4+vjEmP6C/wLvqj3CI65H4Cb+BdzJ/BG2J+wDnbbhI5L9Vw9XrZF038BV1HflROiXUFqgL09q6bOALqtnhNs1tQthWZtfAL9AfcBH3XNxD9AfrExvXxyzkm8H1NSlXvIOf1wXU9k0G/nStmM3HED9zutLj1TQTf+GfZa8onBtP7YfQDFyzCvMbsNo7kov9xtTZ0sSlXbHcBgxw4h9t6XgEN000jaNuw+epUI91o5bdhqm2nuMz3IJ6wvU2jlqP6p/4VCrWdAa22gxHeEqeiqbroLWV1rVu2sCzHJhhOmOP8I+4fgHokXykHFNNveLfVIb/NFMr/3aJndg31vfwGfw+vo13NXH0Y/AyLq3veM3+AXQngyvegYcWAAAAAElFTkSuQmCC"
                                  width="10"
                                  height="10"
                                  alt=""
                                />
                              </div>
                            </div>
                            <span class="Ktbl2">{{ item.orders }} sold</span>
                          </div>
                          <div class="_1okBC">
                            <div class="U-S0j">
                              <span style="font-size: 12px">$</span>
                              <span style="font-size: 24px">{{ item.price }}</span>
                            </div>
                            <div class="_1zEQq">
                              <span style="text-decoration: line-through; color: rgb(153, 153, 153)">${{ item.cost_price }}</span>
                            </div>
                          </div>
                          <div class="T8SnT">
                            <!-- <img aria-hidden="true" dir="" class="_1lYat _1jUmO" src="https://ae01.alicdn.com/kf/Sf1ff53f3b80f474b9cea8f77a281ca3bN/298x64.png" height="16" width="74.5" /> -->
                            <!-- <span class="_1juYg"></span> -->
                            <img aria-hidden="true" dir="" class="_1lYat _1jUmO" src="@/static/css5/imgs/116x64.png" height="16" width="29" />
                            <span class="_1juYg"></span>
                            <span class="_3hksz" title="">{{ $common.cutPriceRate(item.price, item.cost_price) }}</span>
                          </div>
                          <!-- <div class="_3vRdz">
                      <img aria-hidden="true" dir="" class="_1lYat" src="https://ae01.alicdn.com/kf/S1887a285b60743859ac7bdbfca5e0896Z/154x64.png" height="16" width="38.5" />
                      <span class="_1BSEX _3dc7w _1Z6Rx" title="Free shipping" style="color: rgb(25, 25, 25)">Free shipping</span>
                    </div> -->
                        </div>
                        <div class="eFkEW rcmd-hover-more-action">
                          <div class="_3W0hl">
                            <span title="See preview" class="_3bWs9">{{ $t('_xbb._ljgm') }}</span>
                          </div>
                          <!-- <div class="_3W0hl"><span title="Similar items" class="_3bWs9">Similar items</span></div> -->
                        </div>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pc2023-more-to-love--view-more--1RYG5yL">
                <!-- 加载更多 -->
                <div v-show="loadmore">
                  <img class="_2O2Fm" src="@/static/img/loading.gif" />
                </div>

                <div class="_3sm8Z">
                  <span class="_3xk_u" @click="onMore">{{ $t('base.view.more') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- goTop -->
        <div class="common-floor" data-floor-name="side-bar">
          <div class="fix-bar--product-fix-sns--2QBvZ9O" id="fixSns">
            <div class="fix-bar--product-fix-wrap--KBcnwGk" id="fixWrap">
              <a @click="scrollTo('homeTop')">
                <span class="fix-bar--ui-fixed-panel-unit--pbNRFt_" style="visibility: visible; display: flex; align-items: center; justify-content: center">
                  <span class="comet-icon comet-icon-chevronup" style="font-size: 24px">
                    <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false" data-spm-anchor-id="a2g0o.home.0.i8.650c76dbBkUNHI">
                      <path d="M148.053333 685.781333a32 32 0 0 1-2.218666-42.816l2.197333-2.432 326.250667-326.250666a53.333333 53.333333 0 0 1 72.64-2.602667l2.773333 2.602667 326.272 326.250666a32 32 0 0 1-42.837333 47.445334l-2.432-2.197334L512 367.061333 193.28 685.802667a32 32 0 0 1-42.816 2.197333l-2.432-2.197333z"></path>
                    </svg>
                  </span>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 弹出框 -->
    <div class="_3kBox">
      <div :class="['_3KrBP', taskShow ? '_3kShow' : '']">
        <div class="_1-aaU" data-spm-anchor-id="a2g0o.home.0.i0.650c21459Bf0Gu">
          <div>
            <div class="_1u9ll">{{ currentTask.name }}</div>
            <div class="_3hbpp" v-html="currentTask.content"></div>
          </div>
          <div class="_1ZwH_">
            <router-link class="_1-SOk" :to="{ name: 'task_content', query: { id: currentTask.id } }">{{ $t('task.ljcy') }}</router-link>
            <router-link class="Sk1_X _1-SOk" :to="{ name: 'task' }">{{ $t('task.gdrw') }}</router-link>
          </div>
        </div>
        <img class="_24EHh" src="../../static/close.png" alt="" @click="onCloseTask()" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import CategoryList5 from '@/components/v5/category/CategoryList5.vue'
import BannerVue from '@/components/v5/index/BannerVue.vue'
import ActivityVue from '@/components/v5/index/ActivityVue.vue'
import TopGoods from '@/components/v5/index/TopGoods.vue'
import TopStore from '@/components/v5/index/TopStore.vue'
import ScrollTop from '@/components/v5/index/ScrollTop.vue'
// import IndexGoods from '../../components/v5/IndexGoods.vue'
export default defineComponent({
  name: 'indexVue',
  components: {
    CategoryList5,
    BannerVue,
    ActivityVue,
    TopGoods,
    TopStore,
    ScrollTop
    // IndexGoods
  },
  data() {
    return {
      scrollPosition: 0,
      hasShowBg: false,

      //加载更多
      scrollDisabled: false,
      goodsData: [],
      page: 1,
      loadmore: false,

      //当前任务
      taskShow: false,
      currentTask: {
        name: '',
        content: ''
      }
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)

    this.orderTask()
  },
  methods: {
    onCloseTask() {
      this.taskShow = false
    },
    orderTask() {
      this.isLogin = this.$common.isLogin()
      //获取后台任务
      let that = this
      if (this.isLogin) {
        this.$api.orderTask.getOrderTaskList().then((res) => {
          setTimeout(function () {
            that.taskTast(res.data, 0)
          }, 2000)
        })
      }
    },
    taskTast(data, i) {
      if (i >= data.length) {
        i = 0
        return
      }
      //随机时间弹出
      let that = this
      that.currentTask = data[i]
      that.taskShow = true
      setTimeout(function () {
        that.taskShow = false
        that.currentTask = {}
      }, 550000)

      setTimeout(function () {
        //递归
        that.taskTast(data, i + 1)
      }, 6000)
    },
    handleScroll() {
      this.scrollPosition = window.scrollY
      if (this.scrollPosition == 0) {
        this.hasShowBg = false
      } else {
        this.hasShowBg = true
      }
      console.log(this.hasShowBg, this.scrollPosition)
    },
    onMore() {
      this.scrollDisabled = false
      this.onLoadMore(0)
    },
    // 触底加载
    onLoadMore(page = 3) {
      console.log('触发加载')
      if (this.loadmore) {
        return
      }

      if (page > 0 && this.page > page) {
        this.scrollDisabled = true
        return
      }
      this.loadmore = true
      this.getGoods()
    },
    getGoods(size = 24) {
      let that = this
      this.$api.home.getGoodsList({ page: this.page, size: size }).then((res) => {
        that.page++
        res.data.forEach(function (item) {
          that.goodsData.push(item)
        })

        that.loadmore = false
      })
    },
    scrollTo(id) {
      const el = document.getElementById(id)
      el.scrollIntoView({ behavior: 'smooth' })
    }
  }
})
</script>

<style src="@/static/css5/index/index.css"></style>
<style src="@/static/css5/index/1.css"></style>
<style src="@/static/css5/index/gallery-9g91h.css"></style>
<style scoped>
._3xk_u {
  display: inline-block;
  width: 200px;
  height: 40px;
  background-image: linear-gradient(94deg, var(--main-color2), var(--main-color1));
  border-radius: 20px;
  line-height: 40px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
}

.mx-zw {
  z-index: 9;
  height: 60px;
  width: 100%;
  position: absolute;
  background-image: linear-gradient(hsl(0deg 0% 9.8%) 60%, hsl(0deg 0% 9.8%) 75%, transparent);
}
.mx-zw.hide {
  background-image: initial;
}

.mx-bg {
  position: relative;
  background-image: linear-gradient(203deg, #84aef1dd 8.4%, #26ee9f78 30.67%, #15bcdf55 70.04%, #fff0 85%);
}

.mx-bg:before {
  background-image: radial-gradient(100% 68% at 70% 15%, transparent 40%, #fff 79%), conic-gradient(from 90deg at 1px 1px, transparent 90deg, rgba(202, 212, 215, 0.2) 0);
}

.head-zw {
  position: absolute;
  z-index: 10;
  width: 100%;
  background-color: initial;
}

.head-zw.show {
  position: fixed;
  background-color: var(--nav-bg1);
  box-shadow: 0px 1px 1px #000;
  /* border-bottom: 1px solid #f5f5f5; */
}

.bot-zw {
  bottom: -25px;
  z-index: 9;
  height: 125px;
  width: 100%;
  position: absolute;
  /* background-image: linear-gradient(hsl(0deg 0% 9.8%) 60%, hsl(0deg 0% 9.8%) 75%, transparent); */
  background-image: radial-gradient(180% 68% at 70% 15%, transparent 40%, #fff 79%), conic-gradient(from 0deg at 1px 1px, transparent 180deg, rgba(202, 212, 215, 0.2) 0);
}
</style>
<style scoped>
._3kBox {
  /* position: fixed; */
  top: 0;
  z-index: 10000;
}
._3KrBP {
  position: absolute;
  left: -335px;
  z-index: 10000;
  top: 155px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 6px 15px 0 rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 0 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  opacity: 0;
  /* display: none; */
  /* height: 154px; */
  transition: all 0.2s;
  max-width: 550px;
}

._3kShow {
  left: 35px;
  opacity: 1;
  /* display: block; */
  /* transition: all 0.2s; */
}

._3KrBP .UAEqK {
  height: 100%;
  width: 114px;
  position: relative;
}
._3KrBP .UAEqK img {
  width: 114px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

._3KrBP ._1-aaU {
  text-align: left;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 300px;
  height: 100%;
}

._3KrBP ._1-aaU ._1u9ll {
  font-weight: 700;
  font-size: 18px;
  color: #333;
  letter-spacing: 0;
  /* white-space: nowrap; */
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  max-height: 60px;
}

._3KrBP ._1-aaU ._3hbpp {
  font-weight: 700;
  font-size: 13px;
  color: #666;
  letter-spacing: 0;
  margin-bottom: 17px;
  max-height: 240px;
  min-height: 50px;
  overflow: hidden;
}

._3KrBP ._1-aaU ._1ZwH_ {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  width: 100%;
}

._3KrBP ._1-aaU ._1ZwH_ ._1-SOk.Sk1_X {
  background: #f3f4f4;
  color: #666;
}
._3KrBP ._1-aaU ._1ZwH_ ._1-SOk + ._1-SOk {
  margin-left: 8px;
  margin-right: 8px;
}
._3KrBP ._1-aaU ._1ZwH_ ._1-SOk {
  cursor: pointer;
  background: #ffb500;
  border-radius: 15px;
  padding: 6px 28px;
  font-weight: 700;
  font-size: 13px;
  color: #fff;
  letter-spacing: 0;
}

._3KrBP ._24EHh {
  position: absolute;
  right: 4px;
  top: 4px;
  width: 21px;
  height: 21px;
}
</style>