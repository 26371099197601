<template>
  <SvgVue></SvgVue>
  <SvgSymbolVue></SvgSymbolVue>
  <router-view />
  <!-- <router-view :screenWidth="screenWidth" :screenHeight="screenHeight" :scrollTop="scrollTop" /> -->
</template>

<script>
import SvgSymbolVue from '@/components/SvgSymbol.vue'
import SvgVue from '@/components/SvgVue.vue'
export default {
  name: 'App',
  components: {
    SvgSymbolVue,
    SvgVue
  },
  data() {
    return {
      screenWidth: 0, //document.documentElement.clientWidth, //实时屏幕宽度
      screenHeight: 0, //document.documentElement.clientHeight, //实时屏幕高度
      scrollTop: 0 //document.body.scrollTop,
    }
  },
  created() {
    this.$socket.open()
  },
  watch: {
    screenHeight() {
      //let that = this;
      //console.log("实时屏幕高度�?", that.screenHeight);
    },
    screenWidth() {
      //let that = this;
      //console.log("实时屏幕宽度�?", that.screenWidth);
    },
    scrollTop() {
      //let that = this;
      //console.log("实时滚动高度�?", that.scrollTop);
    }
  },
  setup() {
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    if (flag) {
      // let url = `https://wap.${window.location.host}`
      // window.location.href = url
    }
  },
  mounted() {
    if (this._isMobile()) {
      console.log('mobile')
    } else {
      console.log('Windows')
    }

    //连接
    // this.$socket.open()

    //技术支持
    console.log('%c[Base64]:5oqA5pyv5pSv5oyBOiBUZWxlZ3JhbSBAQWxpbnNfZ28=', 'color: #ff3b00')
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
    }
  }
}
</script>
<style scoped>
@import url('@/static/css3/theme.css');
@import url('@/static/lang/lang.css');

/* @import '~ant-design-vue/dist/antd.dark.css'; */
/* @import 'your-theme-file.less'; */
@import url('@/static/css5/antd.css');
</style>
<style>
#app {
  min-width: 1200px;
}

@font-face {
  font-family: Circe;
  src: url(@/static/font2/e841c65b7d08e0aebc3a78bfaa327df5.eot);
  src: url(@/static/font2/6444e13db38127d31ba2b497d0500090.woff) format('woff'), url(@/static/font2/6c28b088081c961225144278a4e42cf3.woff2) format('woff2'), url(@/static/font2/12af16bb3c360852e31ead99e01114ad.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Circe;
  src: url(@/static/font2/16df405a0723b7bda474166ee3b71d5c.eot);
  src: url(@/static/font2/fc2a6498c769c8351fc78ed012d3f8d7.woff) format('woff'), url(@/static/font2/cfdb632d059cf194f12a9b2801fd8474.woff2) format('woff2'), url(@/static/font2/c9a4a2a44c77cfe2df8d0884f6e6ac57.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Circe;
  src: url(@/static/font2/9607f2141b91e16aab242dbeb28e647d.eot);
  src: url(@/static/font2/12b20b27b37b3f9da6dabd78af280d51.woff) format('woff'), url(@/static/font2/eabc62d103e4ac69d508b91db1358834.woff2) format('woff2'), url(@/static/font2/95f63d59c96589a11f05d38d6e231730.ttf) format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

body,
html {
  font-family: Circe, sans-serif;
  /* font-display: swap; */
  /* -moz-osx-font-smoothing: grayscale; */
  /* -webkit-font-smoothing: antialiased; */
  /* background-color: #fff; */
}

.loadmore {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

::-webkit-scrollbar {
  /*隐藏滚轮*/
  /* display: none; */
}

.ant-image-preview-img-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

.ant-image-preview-img {
  height: 80%;
}
/*
.ant-btn-primary {
  color: #000;
  border-color: #ccc;
  background: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-btn:hover,
.ant-btn:focus {
  color: var(--main-color1);
  border-color: var(--main-color1);
  background: #fff;
}

.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus {
  color: #000;
  border-color: var(--main-color1);
  background: #fff;
}

 .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: var(--main-color1) !important;
  border-right-width: 1px !important;
}

 .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: var(--main-color1);
  box-shadow: 0 0 0 2px #62c5c585;
  border-right-width: 1px !important;
  outline: 0;
}

.ant-input:focus,
.ant-input-focused {
  border-color: var(--main-color1);
  box-shadow: 0 0 0 2px #62c5c585;
  border-right-width: 1px !important;
  outline: 0;
}

.ant-input:hover {
  border-color: var(--main-color1);
  border-right-width: 1px !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--main-color1);
  border-color: var(--main-color1);
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: var(--main-color1);
}

.ant-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid var(--main-color1);
  border-radius: 2px;
  visibility: hidden;
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: backwards;
  content: '';
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: var(--main-color1);
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}

.ant-radio-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid var(--main-color1);
  border-radius: 50%;
  visibility: hidden;
  animation: antRadioEffect 0.36s ease-in-out;
  animation-fill-mode: both;
  content: '';
}

.ant-radio-checked .ant-radio-inner {
  border-color: var(--main-color1);
}

.ant-radio-inner::after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 16px;
  height: 16px;
  margin-top: -8px;
  margin-left: -8px;
  background-color: var(--main-color1);
  border-top: 0;
  border-left: 0;
  border-radius: 16px;
  transform: scale(0);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: ' ';
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--main-color1);
  text-shadow: 0 0 0.25px currentcolor;
}

.ant-tabs-ink-bar {
  position: absolute;
  background: var(--main-color1);
  pointer-events: none;
}

.ant-tabs-tab:hover {
  color: var(--main-color1);
}

.ant-pagination-item-active a {
  color: var(--main-color1);
}

.ant-pagination-item-active {
  font-weight: 500;
  background: #fff;
  border-color: var(--main-color1);
}

.ant-pagination-item:hover {
  border-color: var(--main-color1);
  transition: all 0.3s;
}
.ant-pagination-item:hover a {
  color: var(--main-color1);
}

.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: var(--main-color1);
  border-color: var(--main-color1);
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: var(--main-color1);
}
a {
  text-decoration: none !important;
}

.ant-modal-content {
  border-radius: 10px;
  padding: 5px;
}
.ant-image-preview-img-wrapper {
  display: flex;
    align-items: center;
    justify-content: center;
}

.ant-table-column-sorter-up, .ant-table-column-sorter-down {
    font-size: 15px;
} */

#webpack-dev-server-client-overlay {
  display: none !important;
}
</style>
