export default {
  'nav.account': 'Centrum Osobiste',
  'nav.language': 'Chiński uproszczony',
  'signup.success': 'Rejestracja zakończona',

  //error:
  'contact.empty': 'Dane kontaktowe nie mogą być puste',
  'phone.country.empty': 'Prefiks telefonu nie może być pusty',
  'phone.empty': 'Telefon nie może być pusty',
  'email.valid': 'proszę podać swój prawidłowy adres e-mail',

  // base:
  'base.success': 'powodzenie',
  'base.save': 'ratować',
  'base.slide': 'Proszę pociągnąć suwak w celu sprawdzenia',
  'base.send.code': 'Wyślij kod weryfikacyjny',
  'base.send.time': '({time}) Wyślij ponownie kod weryfikacyjny',
  'base.send.success': 'Wysłano pomyślnie',
  'base.view.more': 'Zobacz więcej',
  'base.Language': 'język',
  'base.previous': 'Poprzednia strona',
  'base.next': 'Następna strona',
  'base.total.page': 'Łącznie {strona} stron',
  'base.go.to.page': 'Idź do strony',
  'base.go': 'Iść',
  'base.followers': 'Uwaga',
  'base.follow': 'skupić się na',
  'base.following': 'Już obserwowane',
  'base.visit.store': 'Przeglądaj sklep',
  'base.contact': 'Skontaktuj się z nami',
  'base.delivery': 'Przewidywany czas dostarczenia',
  'base.search': 'szukaj',
  'base.search.text': 'Wprowadź wyszukiwanie słów kluczowych',
  'base.server': 'serwis internetowy',

  //index:
  'text.categories.title': 'Kategorie',
  'text.welcome': 'Zapraszamy na zakupy!',
  'text.wishlist': 'Lista polubień',
  'text.account': 'Centrum Osobiste',
  'text.orders': 'Zamówienie',
  'text.messages': 'Informacja',
  'text.suggest.title': 'Ekskluzywne oferty',
  'text.suggest.content': 'Tylko dla nowych członków Global Supreme!',
  'text.super.deals': 'Najlepszy produkt w niesamowitej cenie.',

  //signup:
  'signup.register': 'rejestr',
  'signup.signin': 'Zaloguj sie',
  'signup.store.signin': 'Logowanie sprzedawcy',
  'signup.sign.out': 'Wyloguj',
  'signup.email': 'adres e-mail',
  'signup.password': 'hasło',
  'signup.qr.password': 'Potwierdź hasło',
  'signup.confirm.password': 'Potwierdź hasło',
  'signup.forgot.password': 'zapomnij hasło',
  'signup.invitation': 'Kod zaproszenia',
  'signup.char': 'postać',
  'signup.contains': 'Zawiera cyfry, litery lub symbole',
  'signup.qr.contains': 'Dwa hasła są niespójne',
  'signup.create.account': 'Utwórz konto',
  'signup.agree': 'Załóż konto i zgódź się',
  'signup.member.agreement': 'Umowa członkowska',
  'signup.and': 'I',
  'signup.privacy.policy': 'Polityka prywatności',
  'signup.email.code': 'Weryfikacja adresu e-mail',
  'signup.last.step': 'ostatni krok',
  'signup.send.email': 'Proszę wpisać 4-cyfrowy kod wysłany na adres {email}',
  'signup.modify.email': 'Modyfikuj adres e-mail',
  'signup.verify.email': 'Zweryfikuj adres e-mail',
  'signup.have.store': 'Posiadaj konto sprzedawcy',
  'signup.goto.signin': 'Logowanie sprzedawcy',
  'signup.no.store': 'Brak konta sprzedawcy',
  'signup.goto.store': 'Zarejestrowany Sprzedawca',
  'signup.next': 'Następny krok',
  'signup.your.email': 'Twoja skrzynka pocztowa',
  'signup.code.text': 'Kod weryfikacyjny',
  'signup.submit.signup': 'Zapisz się teraz',
  'signup.smrz': 'Zweryfikowano',
  'signup.derb': 'Krok 2',
  'signup.qsrxm': 'Proszę podać swoje imię i nazwisko',
  'signup.qsrhm': 'Proszę podać numer identyfikacyjny',

  //forgot:
  'forgot.title': 'Zresetuj hasło',
  'forgot.btn.check': 'Zweryfikuj adres e-mail',
  'forgot.reset.now': 'Zresetuj teraz',

  //store:
  'store.info.open': 'Otwarcie sklepu {y} tygodni',

  // footer:
  'footer.great.value': 'Świetna cena',
  'footer.great.value.desc': 'Oferujemy konkurencyjne ceny na ponad 100 milionów artykułów.',
  'footer.shopping': 'Globalne zakupy',
  'footer.shopping.desc': 'Wysyłamy do ponad 200 krajów i regionów, a nasza strona internetowa jest dostępna w 7 językach.',
  'footer.safe.payment': 'Bezpieczna płatność',
  'footer.safe.payment.desc': 'Zapłać najpopularniejszą i najbezpieczniejszą metodą płatności na świecie.',
  'footer.shop.with.confidence': 'Bezpieczne zakupy',
  'footer.shop.with.confidence.desc': 'Nasza Polityka ochrony kupujących obejmuje cały proces zakupu.',
  'footer.help.center': 'Centrum pomocy',
  'footer.help.center.desc': 'Pomoc 24/7 zapewniająca bezproblemowe zakupy.',
  'footer.terms.conditions': 'Regulamin',
  'footer.return.policy': 'polityka zwrotów',
  'footer.support.policy': 'Polityka wsparcia',
  'footer.privacy.policy': 'Polityka prywatności',
  'footer.be.seller': 'Zostań kupcem',
  'footer.apply.now': 'złóż wniosek natychmiast',
  'footer.stay.connected': 'pozostajemy w kontakcie',

  'footer.about.us': 'o nas',
  'footer.about.company': 'Profil firmy',
  'footer.about.video': 'Wprowadzenie wideo',
  'footer.contact': 'Informacje kontaktowe',

  'footer.my.account': 'Moje konto',
  'footer.my.logout': 'Wyloguj się',
  'footer.my.order': 'Historia zamówień',
  'footer.my.wish': 'Moja lista życzeń',
  'footer.my.join': 'Zostań partnerem członkowskim',
  'footer.email': 'E-mail',
  'footer.gfemail': 'Oficjalny e-mail',
  'footer.fwemail': 'E-mail serwisowy',
  'footer.address': 'adres',

  'apply.success': 'Aplikacja powiodła się',
  'apply.success.desc': 'Aplikacja przebiegła pomyślnie, przejdź do logowania na konto',

  // categories:
  'category.title': 'Powiązane kategorie',
  'category.all': 'Wszystkie kategorie',

  // detail:
  'detail.store.home': 'Strona główna sklepu',
  'detail.sale.items': 'Lista produktów',
  'detail.recommend': 'polecić',
  'detail.orders': 'Obroty',
  'detail.quantity': 'ilość',
  'detail.pieces.available': 'dostępny',
  'detail.delivery': 'Dostarczyć towary',
  'detail.free.shipping': 'Darmowa dostawa',
  'detail.estimated.delivery': 'ETA',
  'detail.days': 'niebo',
  'detail.buy.now': 'Kupić',
  'detail.add.to.cart': 'dodaj do koszyka',
  'detail.buyer.protection': 'Ochrona kupującego',
  'detail.money.guarantee': 'Gwarancja zwrotu pieniędzy',
  'detail.refund.desc': 'Jeśli przedmiot nie jest zgodny z opisem lub nie dotrze, otrzymasz pełny zwrot pieniędzy',
  'detail.description': 'Opis produktu',
  'detail.customer.reviews': 'Opinie klientów',
  'detail.specifications': 'Specyfikacja produktu',
  'detail.top.selling.products': 'najlepiej sprzedające się towary',
  'detail.recommended.for.you': 'polecane Tobie',
  'detail.sold': 'Obroty',
  'detail.receipt': 'potwierdzić odbiór towaru',
  'detail.receipt.title': 'Potwierdzić otrzymanie zamówienia? ',
  'detail.receipt.content': 'Po potwierdzeniu zlecenie transakcji zostaje zrealizowane',
  'detail.comment': 'Komentarz',
  'detail.refund.title': 'Potwierdź wniosek o zwrot pieniędzy',
  'detail.refund.content': 'Po potwierdzeniu zamówienie zostanie zwrócone',
  'detail.sqtk': 'Zażądać zwrotu',

  // confirm:
  'confirm.shipping.address': 'Adres odbiorcy',
  'confirm.change': 'Zmiana',
  'confirm.payment.methods': 'metoda płatności',
  'confirm.summary': 'Osada',
  'confirm.total.item.costs': 'Łącznie pozycje',
  'confirm.total': 'całkowity',
  'confirm.checkout': 'Idź do rozliczenia',
  'confirm.place.order': 'Zamów teraz',
  'confirm.pay.now': 'zapłać natychmiast',
  'confirm.order.desc': "Po kliknięciu 'Złóż zamówienie' potwierdzam, że przeczytałem i potwierdzam",
  'confirm.order.policy': 'Wszystkie warunki i zasady',
  'confirm.payment': 'Zakupy globalne zapewniają bezpieczeństwo Twoich informacji i płatności',

  // address:
  'address.title': 'Adres odbiorcy',
  'address.default': 'domyślny',
  'address.edit': 'edytować',
  'address.delete': 'usuwać',
  'address.new': 'Utwórz nowy adres',
  'address.contact': 'Kontakt',
  'address.address': 'adres',
  'address.phone': 'numer telefonu',
  'address.set.default': 'Ustaw wartość domyślną',
  'address.confirm': 'potwierdzać',
  'address.cancel': 'Anulować',
  'address.del.title': 'Potwierdzić usunięcie adresu?',
  'address.del.content': 'Czy na pewno chcesz usunąć ten adres wysyłki?',

  'payment.method': 'metoda płatności',

  // Shopping Cart:
  'shopping.title': 'wózek sklepowy',
  'shopping.back.buy': 'Wróć do zakupów w ofertach specjalnych',
  'shopping.start.shopping': 'Rozpocznij zakupy',

  // member:
  'member.account': 'Centrum Osobiste',
  'member.overview': 'Przegląd',
  'member.orders': 'Zamówienie',
  'member.payment': 'Moja torebka',
  'member.address': 'Adres wysyłki',
  'member.wishlist': 'Lista polubień',
  'member.followlist': 'Lista obserwacyjna',
  'member.message.center': 'Centrum wiadomości',
  'member.setting': 'Ustawienia osobiste',
  'member.shop.info': 'Przechowaj informację',
  'member.shop.setting': 'Ustawienia sklepu',
  'member.order.notify': 'Powiadomienie o nowym zamówieniu',
  'member.order.tips': 'Wysyłając wiadomość e-mail na adres e-mail konta sprzedawcy',

  // member.order:
  'member.order.title': 'Moje zamówienie',
  'member.order.view.all': 'Pokaż wszystkie',
  'member.order.all': 'Wszystko',
  'member.order.unpaid': 'nie zapłacony',
  'member.order.paid': 'Płatny',
  'member.order.procurement': 'Do kupienia',
  'member.order.seller.paid': 'Zakupione',
  'member.order.processing': 'Aż do',
  'member.order.shipped': 'Dostarczanie',
  'member.order.return': 'Powracający',
  'member.order.completed': 'zakończony',
  'member.order.refund': 'zwrócony',
  'member.order.all.time': 'Wszystko',
  'member.order.empty': 'Nie ma jeszcze żadnych zamówień',
  'member.order.date': 'data',
  'member.order.purchase.date': 'zakup',
  'member.order.cpoy': 'klon',
  'member.order.id': 'Zamówienie',
  'member.order.detail': 'Szczegóły zamówienia',
  'member.order.logistics': 'Informacje logistyczne',

  // member.payment:
  'member.payment.title': 'Moja torebka',
  'member.wallet.balance': 'Saldo w portfelu',
  'member.crypto.recharge': 'uzupełniać',
  'member.crypto.withdrawal': 'wycofać',
  'member.crypto.bank': 'karta bankowa',
  'member.wallet.record': 'Ewidencja wpłat monet',
  'member.bankcard.record': 'Zapis karty bankowej',
  'member.withdrawal.record': 'Ewidencja wypłat',
  'member.income.record': 'Rejestr dochodów',
  'member.transaction.record': 'Zapis transakcjii',
  'member.wallet.freeze': 'Zamrożenie środków',
  'member.wallet.profit': 'Przewidywane zarobki',

  //recharge:
  'recharge.currency': 'Waluta',
  'recharge.protocol': 'protokół',
  'recharge.qrcode': 'Kod QR',
  'recharge.address': 'Adres portfela',
  'recharge.copy': 'Kopiuj adres',
  'recharge.ok.text': 'potwierdzać',
  'recharge.cancel.text': 'Anulować',

  //bank:
  'bank.recharge.title': 'Doładowanie karty bankowej',
  'bank.title': 'Nazwa banku',
  'bank.name': 'Nazwa',
  'bank.account': 'konto',
  'bank.routing': 'rozgromienie',
  'bank.code': 'kod',
  'bank.bankname': 'Nazwa banku',
  'bank.bankaddress': 'Numer konta',
  'bank.ok.text': 'Następny krok',
  'bank.ok.prev': 'Poprzedni',
  'bank.submit': 'Wyślij teraz',
  'bank.amount': 'Kwota doładowania',
  'bank.amount.desc': 'Proszę podać kwotę doładowania',
  'bank.type': 'typ',
  'bank.usd': 'Dolar',
  'bank.eur': 'EUR',
  'bank.receipt.number': 'Numer paragonu',
  'bank.receipt.number.desc': 'Proszę podać numer dowodu transakcji',
  'bank.credential.picture': 'Zdjęcie kuponu',
  'bank.credential.picture.desc': 'Prześlij obraz kuponu',
  'bank.remark': 'zilustrować',
  'bank.upload': 'Załaduj obrazek',
  'bank.text.title': 'Środki ostrożności',
  'bank.text.t1': 'Do przeniesienia nie jest wymagana żadna uwaga, zostaw to pole puste',
  'bank.text.t2': 'W przypadku gospodarstw domowych w Hongkongu jako kraj wybierz Hongkong, nie dodawaj Chin',
  'bank.text.t3': 'Przelewy w USA, w dni robocze, przelewy w Europie przed godziną 15:00, przekazy pieniężne można realizować w godzinach pracy banku!',
  'bank.text.t4': 'Czas przybycia to T+1, a ostatnia płatność w Europie to T+3',
  'bank.text.t5': 'Przed przelewem poinformuj platformę, czy konto jest dostępne. Jeżeli konto zostanie zamknięte, żadna rekompensata nie zostanie wypłacona.',
  'bank.text.t6': 'Wszystkie informacje dotyczące obsługi klienta dotyczące wpłacania pieniędzy za pomocą karty bankowej mają charakter rozstrzygający.',

  //banklist:
  'bank.list.title': 'Nazwa banku',
  'bank.list.code': 'Kod bankowy',
  'bank.list.amount': 'kwota przelewu',
  'bank.list.number': 'Numer paragonu',
  'bank.list.img': 'Zdjęcie kuponu',
  'bank.list.status': 'państwo',
  'bank.list.time': 'czas',
  'bank.list.status1': 'w trakcie przeglądu',
  'bank.list.status2': 'przeszedł',
  'bank.list.status3': 'Odrzucony',

  // Withdrawal:
  'withdrawal.address.desc': 'Proszę podać adres swojego portfela!',
  'withdrawal.number': 'ilość',
  'withdrawal.real.number': 'Faktyczne przybycie',
  'withdrawal.number.desc': 'Proszę podać kwotę wypłaty!',
  'withdrawal.btn.all': 'Wszystko',
  'withdrawal.balance': 'Balansować',
  'withdrawal.commission': 'zamawiać',
  'withdrawal.actual.amount': 'Przybył',
  'withdrawal.notice': 'wskazówka',
  'withdrawal.notice.text': 'Przed przesłaniem pieniędzy proszę potwierdzić, że dane dotyczące adresu płatności są prawidłowe. Po przeniesieniu aktywów nie można ich zwrócić.',
  'withdrawal.notice.content': '{name}({cp_name}) Prowizja',
  'withdrawal.submit': 'składać',
  'withdrawal.choice': 'Wybierz kryptowalutę',
  'withdrawal.yzm': 'Kod weryfikacyjny',
  'withdrawal.fs': 'wysłać',
  'withdrawal.qsryzm': 'Proszę wprowadzić kod weryfikacyjny adresu e-mail',

  // recive:
  'recive.method': 'protokół',
  'recive.amount': 'Kwota',
  'recive.address': 'adres',
  'recive.date': 'czas',
  'recive.status': 'państwo',
  'recive.create.at': 'godzina transakcji',
  'recive.type': 'typ',
  'recive.befor': 'Przed transakcją',
  'recive.balance': 'Balansować',
  'recive.freeze': 'zamrażać',
  'recive.review': 'w trakcie przeglądu',
  'recive.success': 'przeszedł',
  'recive.reject': 'Odrzucony',

  // advertise:
  'advertise.title': 'Centrum Promocji',
  'advertise.shop.title': 'Promocja sklepu',
  'advertise.shop.status': 'państwo',
  'advertise.shop.expired': 'wygasły',
  'advertise.shop.promotion': 'Promowanie',
  'advertise.shop.expire.date': 'Data ważności',
  'advertise.shop.renew': 'Odnowienie reklamy',
  'advertise.shop.payable': 'Kwoty do zapłaty',
  'advertise.shop.explanation': 'Opis promocji',
  'advertise.shop.text': 'Bierz udział w reklamie i promocji platformy, zwiększ ekspozycję sklepu i promuj wielkość zamówień transakcyjnych',
  'advertise.shop.paynow': 'Odnowić teraz',
  'advertise.shop.modal.title': 'Potwierdź płatność',
  'advertise.shop.modal.desc': 'Potwierdź płatność opłat promocyjnych',
  'advertise.shop.modal.btn': 'Potwierdź płatność',

  //Wish List:
  'wishlist.title': 'Lista polubień',
  'wishlist.delete': 'usuwać',
  'wishlist.orders': 'Obroty',

  //Follow List:
  'followlist.title': 'Lista obserwacyjna',
  'followlist.delete': 'usuwać',
  'followlist.follow': 'skupić się na',

  // store:
  'store.dashboard': 'panel',
  'store.products': 'produkt',
  'store.products.list': 'Lista produktów',
  'store.products.reviews': 'Odpowiedź dotycząca produktu',
  'store.orders': 'Zamówienie',
  'store.wallet': 'portfel',
  'store.message': 'Centrum wiadomości',
  'store.setting': 'organizować coś',
  'store.order.total.profit': 'Szacowany całkowity dochód operacyjny',

  //dashboard:
  'dashboard.store.hour.views': 'Wizyty w czasie rzeczywistym',
  'dashboard.store.today.views': 'Dzisiejsze wizyty',
  'dashboard.product.total': 'Całkowita ilość produktu',
  'dashboard.product.today': 'Nowość dzisiaj',
  'dashboard.order.total': 'Całkowita wielkość zamówienia',
  'dashboard.sales.total': 'Szacowany obrót',
  'dashboard.sales.real': 'Rzeczywisty obrót',
  'dashboard.sales.pay': 'Łączne wydatki',
  'dashboard.sales.profit': 'Całkowity dochód',
  'dashboard.commission.total': 'Całkowity dochód',
  'dashboard.commission.today': 'Dzisiejsze zarobki',
  'dashboard.order.sales': 'Sprzedaż towarów',
  'dashboard.sales.list': 'Ranking Sprzedaży',
  'dashboard.goods.cate.rate': 'Proporcja kategorii produktów',
  'dashboard.goods.wish': 'Ranking Polubionych Produktów',
  'dashboard.january': 'Styczeń',
  'dashboard.february': 'Luty',
  'dashboard.march': 'Marsz',
  'dashboard.april': 'Kwiecień',
  'dashboard.may': 'Móc',
  'dashboard.june': 'Czerwiec',
  'dashboard.july': 'Lipiec',
  'dashboard.august': 'Sierpień',
  'dashboard.september': 'Wrzesień',
  'dashboard.october': 'Październik',
  'dashboard.november': 'Listopad',
  'dashboard.december': 'Grudzień',

  // products:
  'products.add.new': 'Dodaj nowy produkt',
  'products.add.from.warehouse': 'Dodaj produkt z magazynu',
  'products.delete': 'usuwać',
  'products.add': 'Dodać do',
  'products.table.img': 'zdjęcie',
  'products.table.name': 'Nazwa produktu',
  'products.table.category': 'Kategoria',
  'products.table.wish': 'tak jak',
  'products.table.stock': 'w magazynie',
  'products.table.price': 'cena',
  'products.table.profit': 'dochód',
  'products.table.action': 'działać',
  'products.search.category': 'Szukaj według kategorii',
  'products.back.product': 'Powrót do listy produktów',
  'products.total': 'Łącznie pozycje',
  'products.yes': 'Tak',
  'products.no': 'Anulować',
  'products.batch.add': 'Zwiększenie partii',
  'products.ask.add': 'Czy na pewno chcesz dodać produkty?',
  'products.batch.delete': 'usunięcie partii',
  'products.ask.delete': 'Czy na pewno chcesz usunąć produkt?',
  'products.top': 'Szczyt',
  'products.syzd': 'Góra strony głównej',
  'products.zdwz': 'Najwyższa pozycja',
  'products.t1': 'Promować',
  'products.t2': 'Preferowane',
  'products.t3': 'Butik',
  'products.t4': 'nic',

  // reviews:
  'reviews.title': 'Recenzja produktu',
  'reviews.product.name': 'towar',
  'reviews.user.name': 'użytkownik',
  'reviews.star': 'wynik',
  'reviews.comment': 'uwagi',
  'reviews.sku': 'Specyfikacja produktu',
  'reviews.imgs': 'Atlas',
  'reviews.created': 'data',

  // store.order:
  'store.order.purchase': 'Kup Teraz',
  'store.order.purchase.desc': 'Czy na pewno chcesz zapłacić za ten produkt?',
  'store.order.purchase.yes': 'zapłać natychmiast',
  'store.order.purchase.no': 'Anulować',
  'store.order.desc': 'Zakup tego zamówienia wymaga płatności, a po zrealizowaniu zamówienia otrzymasz dochód. ',
  'store.order.no': 'numer zamówienia',
  'store.order.number': 'ilość',
  'store.order.buyer': 'Kupujący',
  'store.order.total': 'ryczałt',
  'store.order.will.earning': 'dochód',
  'store.order.profit': 'dochód',
  'store.order.dividends': 'dywidenda',
  'store.order.payment.status': 'Status płatności',
  'store.order.seller.buy.status': 'Stan zakupów',
  'store.order.status': 'Status zamówienia',
  'store.order.date': 'Data zamówienia',
  'store.order.purchase.date': 'Data zakupu',
  'store.order.action': 'działać',
  'store.order.purchase.amount': 'Kwota zakupu',

  //income:
  'income.create.at': 'Rekordowy czas',
  'income.order.sn': 'Numer zamówienia',
  'income.realpay': 'kwota zamówienia',
  'income.profit': 'dochód',

  //Setting:
  'setting.avatar': 'Awatar biznesowy',
  'setting.upload': 'Wgrywać',
  'setting.shop.name': 'Nazwa sklepu',
  'setting.shop.phone': 'numer kontaktowy',
  'setting.shop.address': 'adres sklepu',
  'setting.shop.save': 'ratować',
  'setting.upload.pic': 'Załaduj obrazek',
  'setting.send.pic': 'wyślij zdjęcia',

  //beseller:
  'beseller.title': 'Aplikuj, aby zostać sprzedawcą',
  'beseller.account': 'informacje o koncie',
  'beseller.store': 'Przechowaj informację',
  'beseller.store.name': 'Nazwa sklepu',
  'beseller.store.address': 'adres sklepu',

  //savehome:
  'store.home.title': 'Ustawienia strony głównej',
  'store.home.topimg': 'Górny obraz',
  'store.home.banner': 'Karuzela',
  'store.home.up3': 'Uwagi: Prześlij co najmniej 3 zdjęcia',
  'store.upload.more': 'Prześlij wiele obrazów',
  'store.home.columns': 'kolumna',
  'store.home.bgimg': 'Obraz tła',
  'store.goods.remark': 'Uwagi: 5 produktów w rzędzie, maksymalnie 10 produktów w sekcji',
  'store.home.select': 'Wybierz produkt',
  'store.home.add': 'Dodaj kolumnę',

  //個人信息:
  'setting.update.user': 'informacje osobiste',
  'setting.update.info': 'Modyfikuj informacje',
  'setting.user.avatar': 'zdjęcie profilowe',
  'setting.user.nickname': 'Pseudonim użytkownika',
  'setting.user.nickname.desc': 'Proszę podać pseudonim użytkownika',

  'setting.safe.title': 'Informacja bezpieczeństwa',
  'setting.user.passwd': 'Zmień hasło',
  'setting.passwd.title1': 'Stare hasło',
  'setting.passwd.desc1': 'Proszę podać stare hasło',
  'setting.passwd.title2': 'Nowe hasło',
  'setting.passwd.desc2': 'Proszę podać nowe hasło',
  'setting.passwd.title3': 'Potwierdź hasło',
  'setting.passwd.desc3': 'Proszę podać hasło potwierdzające',
  'setting.invitation': 'Zaprosić przyjaciół',
  'setting.copy': 'klon',

  'adv.add.products': 'Dodaj produkty promocyjne',
  'adv.list.title': 'Promocja produktu',
  'adv.type': 'Typ promocji',
  'adv.begin.time': 'Czas promocji',
  'adv.end.time': 'Koniec czasu',
  'adv.status': 'państwo',
  'adv.status.s1': 'Promowanie',
  'adv.status.s2': 'nad',
  'adv.add.back': 'Powrót do listy promowanych',
  'adv.recharge': 'Doładuj punkty promocyjne',
  'adv.select.goods': 'Wybierz produkt',
  'adv.select.btn': 'Promuj produkty',
  'adv.recharge.title': 'Doładuj punkty promocyjne',
  'adv.recharge.balance': 'Równowaga punktowa',
  'adv.point': 'punkt',
  'adv.point.rate': 'stosunek punktowy',
  'adv.recharge.amount': 'Kwota doładowania',
  'adv.recharge.input.amount': 'Wprowadź kwotę doładowania',
  'adv.select.mod': 'Wybierz sekcję',
  'adv.mod1': 'Zalecane kolumny',
  'adv.mod2': 'Preferowane kolumny',
  'adv.mod3': 'Kolumny sprzedające się na gorąco',
  'adv.mod4': 'Najlepsza lista',
  'adv.mod5': 'Lista kategorii',
  'adv.mod6': 'Szczegóły Promocja',
  'adv.ge': 'ilość',
  'adv.xs': 'Godzina',
  'adv.xzbk': 'Wybierz sekcję',
  'adv.syme': 'Pozostała kwota',
  'adv.tgsc': 'Czas trwania promocji',
  'adv.tgjg': 'Promocyjna cena',
  'adv.syye': 'saldo bieżące',
  'adv.tgye': 'Saldo promocji',
  'adv.yj1': 'oczekiwany',
  'adv.yj2': 'Rozpocznij promocję',
  'adv.pay.point': 'Punkty konsumpcji',

  'wallet.ykcsxf': 'Pobrano opłatę manipulacyjną',
  'wallet.sxf': 'opłata manipulacyjna',
  'wallet.zxje': 'Stawka minimalna',
  'wallet.24hxe': 'limit 24-godzinny',
  'wallet.desc.text': 'Urzędnik pod żadnym pozorem nie poprosi Cię o przelew pieniędzy na określone konto ani nie poprosi Cię o kod weryfikacyjny. Prosimy nie brać udziału w pośrednictwach handlowych, praniu brudnych pieniędzy, nielegalnym zbieraniu funduszy i innych nielegalnych działaniach oraz uważać na oszustwa internetowe',
  'wallet.cunbi.text': 'Możesz wpłacić tylko {name} na ten adres, inne aktywa nie będą mogły zostać odzyskane',

  'credit.title': 'kredyty',
  'credit.ed': 'Kontyngent',
  'credit.hk': 'Spłata',
  'credit.kyye': 'Dostępne saldo',
  'credit.dqqk': 'Zadłużenie bieżące',
  'credit.hkje': 'Kwota spłaty',
  'credit.hkje.desc': 'Proszę podać kwotę spłaty',
  'credit.cgje': 'Kwota zakupu',
  'credit.qrz': 'Przejdź do uwierzytelniania',
  'credit.srxyj': 'Wprowadź kwotę płatności kredytu',

  'store.gzl': 'Uwaga',
  'store.wxz': 'Bez limitu',

  'auth.smrz': 'Zweryfikowano',
  'auth.rzlx': 'typ',
  'auth.sfzm': 'Przód dowodu osobistego',
  'auth.sffm': 'Odwrotna strona dowodu osobistego',
  'auth.zsxm': 'prawdziwe imię',
  'auth.zjhm': 'Numer certyfikatu',
  'auth.yyzz': 'zezwolenie na prowadzenie działalności',
  'auth.gsmc': 'Nazwa firmy',
  'auth.shz': 'w trakcie przeglądu',
  'auth.ytg': 'przeszedł',
  'auth.ybh': 'Odrzucony',
  'auth.zt': 'państwo',
  'auth.gr': 'osobisty',
  'auth.gs': 'przedsiębiorstwo',
  'auth.ljtj': 'Złóż wniosek o certyfikację',
  'auth.wrz': 'nie certyfikowany',

  'credit.qts': 'Wyświetl opis',
  'store.spss': 'Górny limit produktu',
  'store.dpfh': 'Dywidendy sklepu',
  'store.qydj': 'Poziom kapitału własnego',
  'store.level': 'klasa',
  'store.jian': 'sztuka',

  'order.notify.email': 'Ze względu na kontrolę ryzyka poczty e-mail, e-maile z przypomnieniem o zamówieniu na platformie są często wysyłane i blokowane, aby otrzymać je na czas, zaleca się dodanie {e} do białej listy e-maili logowania"',

  'setting.sjxx': 'Informacje biznesowe',
  'setting.smrz': 'Zweryfikowano',
  'setting.aqzx': 'Centrum Bezpieczeństwa',
  'setting.tzxx': 'Informacje o powiadomieniach',
  'setting.dpzx': 'Strona główna dekoracji',
  'setting.sjqy': 'Prawa Biznesu',
  'setting.sjdjqy': 'Opis praw na poziomie sprzedawcy',

  'setting.fhbky': 'Aktualna dywidenda nie jest dostępna',
  'setting.spgzyzy': 'Regulamin i wytyczne sklepu',

  'desc.dqqy': 'Aktualny kapitał',
  'desc.xjqy': 'Prawa podrzędne',
  'desc.sjqy_0': 'Limit kredytowy sklepu',
  'desc.sjqy_1': 'Dywidendy sklepu',
  'desc.sjqy_2': 'Ilość produktów, które można opublikować',

  'share.yqlb': 'Lista zaproszeń',
  'share.zmj': 'Sprzedawca ogółem',
  'share.zdd': 'Całkowite zamówienie',
  'share.zsy': 'Całkowity dochód maklerski',
  'share.wcl': 'Nieprzetworzone',
  'share.ycl': 'Obrobiony',
  'share.ywc': 'zakończony',
  'share.dpmc': 'Nazwa sklepu',
  'share.ddl': 'Ilość zamówienia',
  'share.jjsy': 'Dochód z działalności maklerskiej',
  'share.zts': 'Całkowita liczba pozycji',

  'chat.state': 'państwo',
  'chat.zx': 'online',
  'chat.ljz': 'złączony',

  'bind.wallet.title': 'Adres do wypłaty',
  'bind.address.title': 'Dodaj adres',
  'bind.bj': 'edytować',
  'bind.sc': 'usuwać',
  'bind.qrsc': 'Czy na pewno chcesz usunąć ten adres?',
  'bind.qbdz': 'Adres portfela',
  'bind.tjsj': 'Dodaj czas',
  'bind.cz': 'działać',
  'withdrawal.address.choice': 'Proszę wybrać adres wypłaty!',

  'order.sxsj': 'Efektywny czas',
  'order.ljsj': 'Ze skutkiem natychmiastowym',
  'order.dssj': 'Skutek w określonym czasie',

  'store.st.zt': 'Stan sklepu',
  'store.st.zc': 'normalna',
  'store.st.zc.desc': 'Twój sklep działa normalnie, proszę o jego utrzymanie',
  'store.st.yc': 'nieprawidłowy',
  'store.st.yc.desc': 'W Twoim sklepie wystąpiły nieprawidłowości w zamówieniu lub otrzymano reklamacje od klientów',
  'store.st.dj': 'zamrażać',
  'store.st.dj.desc': 'Twój sklep wiąże się z pewnym ryzykiem operacyjnym, które powoduje zamrożenie środków',
  'store.st.jy': 'Wyłączyć',
  'store.st.jy.desc': 'Twój sklep naruszył odpowiednie umowy i zasady platformy i został wyłączony',

  'task.sy': 'pierwsza strona',
  'task.rwlbo': 'Lista zadań',
  'task.rwxqo': 'Szczegóły zadania',
  'task.ljcy': 'Weź udział teraz',
  'task.gdrw': 'Więcej zadań',
  'task.rwlb': 'Lista zadań pobierania zamówień',
  'task.qbrw': 'Wszystkie zadania',
  'task.wcyd': 'Wziąłem udział',
  'task.qdz': 'Zamówienie w toku',
  'task.ywc': 'zakończony',
  'task.djy': 'Do handlu',
  'task.ddje': 'kwota zamówienia',
  'task.cyrs': 'Liczba uczestników',
  'task.ewsy': 'Dodatkowy przychód',
  'task.jzsj': 'termin ostateczny',
  'task.ckxq': 'sprawdź szczegóły',
  'task.rwxq': 'Szczegóły zadania',
  'task.cylb': 'Lista uczestników',
  'task.qdcyrw': 'Czy na pewno chcesz wziąć udział w tym zadaniu zamówienia?',
  'task.sd': 'Tak',
  'task.bue': 'NIE',

  'task.wdsy': 'dochód',
  'task.wdfh': 'dywidenda',
  'task.wdqd': 'Zdobądź zamówienie',
  'task.dd.leixin': 'Typ zamówienia',
  'task.dd.qdrw': 'Zadanie pobrania zamówienia',
  'task.dd.ptdd': 'Zwykły porządek',

  'flow.lltg': 'Promocja ruchu',
  'flow.gmll': 'Pakiet danych zakupowych',
  'flow.dqtc': 'Obecny pakiet',
  'flow.tc1': 'kombinacja',
  'flow.sy2': 'reszta',
  'flow.tian3': 'niebo',
  'flow.jlzs': 'Całkowita liczba rekordów',
  'flow.lltc': 'Pakiet danych',
  'flow.ll': 'przepływ',
  'flow.gmsj': 'Czas zakupu',
  'flow.tcjg': 'Cena paczki',
  'flow.sjzf': 'rzeczywista płatność',
  'flow.tcsm': 'Opis pakietu',
  'flow.tcsm.sm': 'Zakup tego pakietu zwiększy widoczność Twojego sklepu i zwiększy ruch klientów. Użyj tego, aby uzyskać więcej zamówień i zwiększyć przychody sklepu. ',

  'flow.tcmc': 'Nazwa pakietu',
  'flow.gmsc': 'Czas trwania zakupu',
  'flow.zje': 'ryczałt',
  'flow.jzsj': 'termin ostateczny',
  'flow.gmrq': 'Data zakupu',
  'flow.tian1': 'niebo',
  'flow.zhou1': 'tydzień',
  'flow.yue1': 'księżyc',

  'share.dj': 'stopień',
  'share.hz': 'Przenosić',
  'share.hzd': 'Przenieść do',
  'share.qbye': 'Saldo w portfelu',
  'share.tgje': 'Kwota promocyjna',
  'share.hzje': 'Kwota przelewu',
  'share.qsrhzje': 'Proszę podać kwotę przelewu',

  '_inv._title': 'Opis zaproszenia',
  '_inv._t1': '1.Zaproszenie i relacja zaproszona',
  '_inv._desc1': 'A zaprasza B, B zaprasza C, C zaprasza D. Najwyższy poziom to 4. Jeśli D ponownie zaprosi E, E nie będzie miał nic wspólnego z A. ',
  '_inv._t2': '2. Zaproś ludzi, aby otrzymać nagrody',
  '_inv._desc2': 'A otrzymuje 4% całkowitej kwoty wykonania zamówienia B, A otrzymuje 2% całkowitej kwoty wykonania zamówienia C, A otrzymuje 1% całkowitej kwoty wykonania zamówienia D',

  '_index._gm': 'Kupić',
  '_index._sm': 'Opis: Zwiększ ekspozycję sklepu i przyciągnij ruch klientów',

  '_shop._update': '{n} razy/miesiąc, zmodyfikowano {m} razy w miesiącu',
  '_chat._hc': 'wycofać',

  '_footer._suom': 'Nota polityczna',

  '_footer._platform._qualification': 'Kwalifikacja firmy',
  '_store._ptzz': 'Kwalifikacje związane z platformą ShopifyOutlets Global',

  '_abt._gywm': 'o nas',
  '_abt._syms': 'Model biznesowy',
  '_abt._qyzr': 'Odpowiedzialność zbiorowa',
  '_abt._lxwm': 'Skontaktuj się z nami',

  '_wsj._wsjms': 'Świętuj Halloween w wielkim stylu w naszym upiornym sklepie Halloween. Niezależnie od tego, czy szukasz podstępu, czy poczęstunku w tym strasznym okresie, w naszej kolekcji kostiumów, dekoracji i akcesoriów na Halloween znajdziesz coś dla całej rodziny. Wyraź mrożące krew w żyłach oświadczenie dzięki naszym kolekcjom dla kobiet i mężczyzn lub uwolnij wyobraźnię swojego dziecka za pomocą kostiumów fantazyjnych. Od niezapomnianych uroczych artykułów gospodarstwa domowego i zabawek po efektowne kosmetyki i kosmetyki – znajdź idealne produkty, aby uczcić najbardziej ekscytujące wakacje w roku. ',

  '_wsj._b1': 'Jesienny wystrój domu',
  '_wsj._b2': 'dynia',
  '_wsj._b3': 'Dom',
  '_wsj._b4': 'dziecięcy',
  '_wsj._b5': 'Dziecko',
  '_wsj._b6': 'damski',
  '_wsj._b7': 'Maskarada',
  '_wsj._b8': 'być uosobieniem',

  '_wsj._cj': 'dołączyć',
  '_wsj._gz': 'Zasady działania',
  '_wsj._flss': 'Wyszukiwanie kategorii',
  '_wsj._wsnz': 'Kostiumy Halloweenowe dla kobiet',
  '_wsj._wsn': 'Kostium męski na Halloween',
  '_wsj._wst': 'Halloweenowa odzież dziecięca',
  '_wsj._wszs': 'Dekoracja Halloweenowa',
  '_wsj._wslp': 'Prezent na Halloween',

  '_wsj._desc1': "Specjalność Halloween, Cukierek albo psikus!' W październiku przygotowaliśmy dla Ciebie imprezę karnawałową! Przyjdź i zgarnij wszelkiego rodzaju przerażające i szokujące produkty, czekają na Ciebie duże zniżki! ",
  '_wsj._lkm': 'iść na zakupy',
  '_wsj._wsj': 'Halloween',

  '_wsj._wsjjz': 'Nadchodzi halloween',
  '_wsj._mzbt': 'Kalendarz Adwentowy Urody',
  '_wsj._mznr': 'Właśnie rozpoczęło się odliczanie do najwspanialszego okresu w roku i mamy kalendarz adwentowy, który zadowoli każdego. ',

  '_chr._tt1': 'Zakupy świąteczne',
  '_chr._tt2': 'Ozdoby świąteczne',
  '_chr._tt3': 'Kup wszystkie prezenty',
  '_chr._tt4': 'Kup całą świąteczną piżamę',

  '_chr._dt1': 'Okres poprzedzający Boże Narodzenie jest tak samo magiczny, jak ten wielki dzień. Zatem uporządkuj swoje prezenty, udekoruj swoje korytarze i przygotuj pasującą piżamę na magię świąt Bożego Narodzenia. ',

  '_chr._dtt1': 'Kostium bożonarodzeniowy',
  '_chr._dtt2': 'Rodzinne Święta Bożego Narodzenia',
  '_chr._dtt3': 'Kalendarz adwentowy',
  '_chr._dtt4': 'Boże Narodzenie w domu',
  '_chr._dtt5': 'wygodny sklep',
  '_chr._dtt6': 'Meble świąteczne',

  '_chr._tre1': 'teraz popularne',
  '_chr._tre2': 'Wprowadź do swojego domu odrobinę świątecznej zabawy dzięki trendowi, który wszyscy pokochają. ',
  '_chr._tre3': 'piernik',
  '_chr._tre4': 'Świąteczny pudding',
  '_chr._tre5': 'Świąteczne Gonki',

  '_chr._ttre1': 'Kartki i papier pakowy',
  '_chr._ttre2': 'Zabawki i gry',
  '_chr._ttre3': 'Prezenty z jedzeniem i napojami',
  '_chr._ttre4': 'wypełniacz do pończoch',
  '_chr._ttre5': 'Sekretny Mikołaj',
  '_chr._ttre6': 'Pierwsze Święta Dziecka',
  '_chr._ttre7': 'piękny prezent',
  '_chr._ttre8': 'Prezent premium',
  '_chr._ttre9': 'Spersonalizowany prezent',

  '_sdj._sdnz': 'Ubrania Damskie',
  '_sdj._sdn': 'Męski',
  '_sdj._sdetlw': 'Prezent',
  '_sdj._sdzs': 'udekorować',
  '_sdj._sdjj': 'Zabawka',

  '_sdj._title1': 'Święta nadchodzą',
  '_sdj._desc1': "Boże Narodzenie: czas dawania i wdzięczności'. W grudniu przygotowaliśmy dla Ciebie jasno udekorowane i ciepłe przyjęcie rodzinne! Przyjdź i zgarnij różnorodne jasne i ciepłe produkty z ogromnymi rabatami, które na Ciebie czekają! ",

  '_ssd._lhpp': 'Elastyczna płatność',
  '_ssd._lzhq': 'Źródło globalne',
  '_ssd._pwyx': 'Oceniony jako doskonały',
  '_ssd._qsth': 'Łatwe powroty',
  '_ssd._jgppcn': 'Dopasowanie ceny i obietnica',
  '_ssd._ztbt': 'Utwórz globalne zakupy w jednym miejscu',
  '_ssd._yzspl': 'Kupuj produkty bezpośrednio od ponad 8 000 niezależnych marek',
  '_ssd._zcgm': 'Zarejestruj się, aby dokonać zakupu',
  '_ssd._zccs': 'Zarejestruj się na sprzedaż',

  '_ssd._dlwz': 'Zaloguj się, aby się zarejestrować',

  '_ssd._qjrgwc': 'Dodaj wszystko do koszyka',

  '_zddy._gzlb': 'Lista obserwacyjna',
  '_zddy._zsersxs': 'Sprzedane w shopifyoutlets',
  '_zddy._zccwsj': 'Zarejestruj się jako sprzedawca',
  '_zddy._dlyh': 'Zaloguj sie',
  '_zddy._zcyh': 'rejestr',
  '_zddy._xyh': 'nowy użytkownik',
  '_zddy._gwc': 'wózek sklepowy',
  '_zddy._znxx': 'Informacja',
  '_zddy._zshy': 'najbardziej popularny',
  '_zddy._ddl': 'Zamówienie',
  '_zddy._mysj': 'brak danych',
  '_zddy._ckgd': 'Zobacz więcej',
  '_zddy._tjsp': 'Rekomendowane produkty',
  '_zddy._rmsp': 'Gorące produkty, nowe trendy, większe możliwości zakupowe',
  '_zddy._xsp': 'Nowy produkt',
  '_zddy._jlzzr': 'Najgorętsze pozycje ostatnich dwóch tygodni',
  '_zddy._ljgm': 'Kup Teraz',
  '_zddy._gdsp': 'Bardziej popularne przedmioty',
  '_zddy._ssbt': 'szukaj',
  '_zddy._ssgjz': 'Szukam..',

  '_zddy._bdhb': 'waluta lokalna',
  '_zddy._xzbdhb': 'Wybierz walutę lokalną',
  '_zddy._zje': 'ryczałt',
  '_zddy._xzzffs': 'Proszę wybrać odpowiadającą Ci metodę płatności',
  '_zddy._jmhb': 'Kryptowaluta',

  '_zddy._cp': 'produkt',
  '_zddy._sj': 'Kupiec',
  '_zddy._mj': 'Sprzedawca',
  '_zddy._fhsy': 'Powrót do strony głównej',

  '_sjsy._ddpl': 'Lista zamówień',

  '_yhqd._czbz': 'Przed doładowaniem skontaktuj się z dedykowaną obsługą klienta, aby potwierdzić informacje dotyczące doładowania',
  '_yhqd._lxkf': 'Biuro obsługi klienta',
  '_yhqd._qsrje': 'Proszę podać kwotę zapisanej wartości',
  '_yhqd._wrhyhtd': 'Brak dostępu do banku',
  '_yhqd._yhzz': 'transfer pieniędzy',

  '_yhbt._yhlx': 'Typ banku',
  '_zcyz._sjdxyz': 'Weryfikacja SMS-em mobilnym',
  '_zcyz._sjhm': 'numer telefonu',
  '_zcyz._srsjhm': 'Wprowadź numer telefonu komórkowego',
  '_zzsd._qsrzzje': 'Proszę podać kwotę przelewu',
  '_zzsd._zzje': 'Kwota przelewu',

  '_txcd._qsrtxje': 'Proszę wpisać kwotę wypłaty',
  '_txcd._skr': 'Odbiorca płatności',
  '_txcd._skr._ms': 'Proszę podać imię i nazwisko posiadacza karty',
  '_txcd._skzh': 'Konto rozliczeniowe',
  '_txcd._skzh._ms': 'Proszę podać numer rachunku płatniczego',
  '_txcd._tjyhk': 'Dodaj kartę bankową',
  '_txcd._txje': 'Kwota wypłaty',
  '_txcd._wdyhdz': 'Numer konta',
  '_txcd._yhdm': 'Kod programu',
  '_txcd._yhdm._ms': 'Proszę wprowadzić kod banku',
  '_txcd._yhdz': 'adres',
  '_txcd._yhdz._ms': 'Proszę podać adres swojego banku',
  '_txcd._yhlx': 'typ',
  '_txcd._yhlx._ms': 'Proszę podać typ karty bankowej',
  '_txcd._yhly': 'rozgromienie',
  '_txcd._yhly._ms': 'Proszę podać routing banku',
  '_txcd._yhmc': 'Bank',
  '_txcd._yhmc._ms': 'Proszę podać nazwę banku',
  '_txcd._yhtx': 'Wypłata z banku',

  '_jys._gmjmhb': 'Kup kryptowalutę',
  '_jys._gmzy': 'Możesz wejść na poniższe giełdy, zarejestrować konto i kupować kryptowaluty zgodnie z polityką obowiązującą w Twoim kraju. Następnie dodaj wartość do konta platformy! ',

  '_kdydj._bt': 'Otwórz sklep i wygraj wspaniałe nagrody',
  '_kdydj._xyzbt': 'Szczęście wciąż się odwraca',
  '_kdydj._yx': 'Pompuj {n} razy',
  '_kdydj._xh': 'Zużyj {n} punktów',

  '_kdydj._jf': 'całka',
  '_kdydj._zjjl': 'Zwycięski rekord',

  '_kdydj._hdgz': 'Zasady działania',
  '_kdydj._qx': 'Proszę',
  '_kdydj._dl': 'Zaloguj sie',

  '_kdydj._mjdl': 'Logowanie sprzedawcy',
  '_kdydj._yxh': 'Poczta',
  '_kdydj._yxhsm': 'proszę podać swój adres e-mail',
  '_kdydj._yxhmm': 'Proszę wpisać hasło',
  '_kdydj._rgmyzh': 'Jeśli nie masz konta',
  '_kdydj._djzc': 'Kliknij, aby się zarejestrować',
  '_kdydj._wjmm': 'zapomnij hasło',

  '_kdydj._wdjp': 'moja nagroda',
  '_kdydj._zjts': 'Zwycięskie typy',
  '_kdydj._jx': 'Kontynuować',
  '_kdydj._czl': 'Wygrał losowanie',

  '_kdydj._ljjl': 'Nagroda skumulowana',
  '_kdydj._ycy': 'Brał udział',
  '_kdydj._cy': 'Weź udział {n} razy',
  '_kdydj._lq': 'odbierać',
  '_kdydj._ylq': 'Otrzymane',
  '_kdydj._wdd': 'nie osiągnięty',

  '_dk._dkbt1': 'Pożyczki dla przedsiębiorców rozwiązują problem pożyczania pieniędzy',
  '_dk._dkbt2': 'Świadczenie usług obrotu kapitałowego kredytów dla przedsiębiorców',
  '_dk._dkbt3': 'Pomożemy Ci rozwiązać problemy związane z pożyczką, takie jak brak środków, brak możliwości pożyczenia pieniędzy i wstyd poprosić znajomych o pożyczenie pieniędzy. Zapewniamy profesjonalną obsługę przez cały proces i odpowiadamy na Twoje pytania w dowolnym momencie. Naszym celem jest udzielenie każdej pożyczki przejrzysty, dzięki czemu Klienci mogą z niego szybko i bezpiecznie korzystać. ',
  '_dk._zxsq': 'Aplikacja online',
  '_dk._wddk': 'Moja pożyczka',
  '_dk._aqwy': 'bezpieczny i bezproblemowy',
  '_dk._lhed': 'Elastyczny limit',
  '_dk._lhedb': 'Kontyngent',
  '_dk._jsfk': 'szybka pożyczka',
  '_dk._jsfkb': 'Przekaż recenzję w najkrótszym czasie, a płatność zostanie dokonana natychmiast po recenzji. ',
  '_dk._aqww1': 'bezpieczny i bezproblemowy',
  '_dk._aqww1b': 'Żadnego kredytu hipotecznego, żadnego depozytu, żadnych rozmów zwrotnych, to jest sprawa prywatna. ',

  '_dk._gywm': 'o nas',
  '_dk._gywm1': "Jesteśmy zaangażowani w świadczenie usług finansowych w oparciu o filozofię biznesową 'najpierw klient' i misję rozwiązywania problemów klientów związanych z obrotem kapitałowym. Od momentu powstania firma pomogła niezliczonej liczbie klientów rozwiązać problemy związane z obrotem kapitałowym. ",
  '_dk._gywm2': 'Kontyngent',
  '_dk._gywm3': 'Jeśli nie chcesz pożyczać pieniędzy od kolegów i znajomych, przyjdź do nas. ',
  '_dk._gywm4': 'Zaangażowany w obsługę pożyczek dla przedsiębiorców',
  '_dk._gywm5': 'Rozwiązanie Twoich problemów finansowych',
  '_dk._hzhb': 'Partner – Platforma Pożyczek Internetowych',
  '_dk._smwda': 'Użytkownicy globalnej stacji shopifyoutlets Business pochodzą ze 112 krajów na całym świecie i do rozliczeń wykorzystują kryptowalutę USDT/ETH/BTC/TRX. Kryptowaluta to sposób handlu bez granic, umożliwiający natychmiastowe, tanie transakcje na całym świecie, bez czekania i bez opłat międzynarodowych. ',

  '_dk._yjwa': 'shopifyoutlets Business© Ltd. 2022-2024 Wszelkie prawa zastrzeżone',
  '_dk._yjwab': 'ShopifyOutlets Holdings Global Sourcing Limited (0008945) posiada autoryzację Urzędu Nadzoru Finansowego i jest regulowany przez Urząd Nadzoru Finansowego jako broker kredytowy i współpracuje wyłącznie z pożyczkodawcą Home Retail Group CardServices Limited, działającym pod nazwą shopifyoutlets Business Financial Services, podmiot autoryzowany i regulowany przez Urząd Nadzoru Finansowego przedłużyć kredyt. Firma Home Retail GroupCard Services Limited jest zarejestrowana w Wielkiej Brytanii (04007072), Avebury, 489-499 Avebury Boulevard, Milton Keynes, Wielka Brytania. MK9 2NW. ',

  '_dk._cyzjsq': 'Wypełnij wniosek o pożyczkę biznesową',
  '_dk._dkll': 'Sprocentowanie kredytów',
  '_dk._dkll2': 'Opis oprocentowania kredytu (kliknij aby wyświetlić)',
  '_dk._gs1': 'formuła',
  '_dk._gs2': 'Odsetki',
  '_dk._gs3': 'Kwota kredytu',
  '_dk._gs4': 'roczna stopa procentowa',
  '_dk._gs5': 'Dni kredytowe',
  '_dk._gs6': 'Na przykład',
  '_dk._gs7': 'Kwota kredytu',
  '_dk._gs8': 'Cykl kredytowy',
  '_dk._gs9': 'niebo',
  '_dk._fkfs': 'Metoda pożyczkowa',
  '_dk._zhye': 'bilans konta',
  '_dk._dkqx': 'Okres kredytowania',
  '_dk._dkqx1': 'Wybierz okres kredytowania',
  '_dk._sqje': 'Kwota wniosku',
  '_dk._qsrdk': 'Proszę wpisać pożyczkę',
  '_dk._dkje': 'Kwota kredytu',
  '_dk._gj': 'Obywatelstwo',
  '_dk._qxzgj': 'Proszę wybrać narodowość',
  '_dk._sqr': 'petent',
  '_dk._qsrsqsm': 'Proszę wpisać imię i nazwisko wnioskodawcy',
  '_dk._zjhm': 'Numer certyfikatu',
  '_dk._qsrzjhm': 'Proszę podać numer identyfikacyjny',
  '_dk._zjzp': 'zdjęcie identyfikacyjne',
  '_dk._zjzp1': 'Proszę przesłać zdjęcie przedniej strony dowodu osobistego',
  '_dk._zjfm1': 'Proszę o przesłanie zdjęcia odwrotnej strony dowodu osobistego',
  '_dk._zjsc1': 'Proszę pokazać dowód tożsamości i zdjęcie',
  '_dk._zjzp2': 'Wybierz obraz',
  '_dk._pzslt': 'Przykład fotografii',
  '_dk._wytyb': 'Przeczytałem i zgadzam się',
  '_dk._jkxy': 'Umowa pożyczki',
  '_dk._tjsq': 'złożyć wniosek',

  '_dk._wddk2': 'Moja pożyczka',
  '_dk._ydk': 'Pożyczony',
  '_dk._cslx': 'Generuj zainteresowanie',
  '_dk._yjhk': 'Szacowana spłata',
  '_dk._dkjl': 'Rekord pożyczki',
  '_dk._hkjl': 'Rejestr spłaty',
  '_dk._sqrq': 'Data złożenia wniosku',
  '_dk._hkzq': 'Okres spłaty',
  '_dk._gdll': 'stała stopa procentowa',
  '_dk._hkz': 'Spłata w toku',
  '_dk._yhk': 'spłacone',
  '_dk._sqz': 'Aplikowanie',
  '_dk._ytg': 'przeszedł',
  '_dk._ybh': 'Odrzucony',
  '_dk._tqhk': 'Przedpłata',

  '_dk._ljhk': 'Spłacaj natychmiast',
  '_dk._qrytqhk': 'Czy na pewno chcesz ubiegać się o spłatę z góry?',
  '_dk._qrytqhk1': 'zilustrować',
  '_dk._dkxq': 'Szczegóły kredytu',
  '_dk._dkje1': 'Kwota kredytu',
  '_dk._yhlx': 'należne odsetki',
  '_dk._hkze': 'Całkowita spłata',
  '_dk._sqhkz': 'Ubieganie się o zwrot',
  '_dk._zxydbty': 'Proszę uważnie przeczytać i zaznaczyć, czy wyrażasz zgodę na "Umowę pożyczki"',
  '_dk._zzsqz': 'Aplikowanie',

  '_dk._dksq': 'Kredyt',

  '_dk._cs': 'Parametry',
  '_dk._nllsm': 'Wyrażone procentowo, na przykład: 6,25%',
  '_dk._dktssm': 'Całkowita liczba dni od początku do końca zatwierdzenia pożyczki',
  '_dk._yndts': 'Liczba dni w roku: zwykle 365 dni',
  '_dk._zlj': 'Opłata za opóźnienie',

  '_xbb._xz': 'Pobierz',
  '_xbb._xzapp': 'Pobierz aplikację ShopifyOutlets',
  '_xbb._smewm': 'Zeskanuj kod QR, aby pobrać',
  '_xbb._syfl': 'Wszystkie kategorie',
  '_xbb._gdfl': 'Więcej',
  '_xbb._sbbt1': 'Szybka dostawa',
  '_xbb._sbbt2': 'W przypadku problemów możliwy jest zwrot pieniędzy',
  '_xbb._sbbt3': 'Darmowe zwroty',
  '_xbb._sbbt4': 'W ciągu 90 dni',
  '_xbb._tjsp1': 'Polecane produkty',
  '_xbb._tjsp2': 'Wiele rabatów',
  '_xbb._tjsp3': 'Zniżka ograniczona czasowo',

  '_xbb._asjgw': 'Zakupy według sklepu',
  '_xbb._gdsp': 'Więcej produktów',
  '_xbb._ljgm': 'Kup teraz',
  '_xbb._ptbz': 'Gwarancja ShopifyOutlets',
  '_xbb._aqzf': 'Bezpieczna płatność',
  '_xbb._aqzf1': 'Metoda płatności używana przez wielu międzynarodowych klientów',
  '_xbb._aqys': 'Bezpieczeństwo i prywatność',
  '_xbb._aqys1': 'Szanujemy Twoją prywatność, więc Twoje dane osobowe są bezpieczne',
  '_xbb._mjbh': 'Ochrona kupujących',
  '_xbb._mjbh1': 'Jeśli Twoje zamówienie nie zostanie dostarczone przed przewidywanym terminem lub nie jesteś zadowolony z zamówienia, możesz otrzymać zwrot pieniędzy',
  '_xbb._mzsm': 'Ostrzeżenie/Zastrzeżenie',

  '_xbb._sjxx': 'Biznesmen',
  '_xbb._ggms': 'Specyfikacja',
  '_xbb._mswa': 'Opis',
  '_xbb._khpl': 'Komentarze klientów',
  '_xbb._zmfk': 'Pozytywna opinia',
  '_xbb._gzrs': 'Śledź',
  '_xbb._gzrsan': 'Śledź',
  '_xbb._ygzrsan': 'Już obserwowany',
  '_xbb._fsxx': 'Wiadomość',
  '_xbb._myrpl': 'Nie ma jeszcze komentarzy',
  '_xbb._llgd': 'Wyświetl więcej',
  '_xbb._pdcl': 'Obietnica',
  '_xbb._myf': 'Bezpłatna wysyłka',
  '_xbb._sdsj': 'Dostarczono w ciągu około 5-10',
  '_xbb._ksjh': 'Szybka dostawa',
  '_xbb._bgdstk': 'Zwrot pieniędzy za zagubioną paczkę',
  '_xbb._shbgtk': 'Jeśli przedmiot jest uszkodzony, zwróć pieniądze',
  '_xbb._s30gtk': 'Zwrot pieniędzy, jeśli przesyłka nie zostanie wysłana w ciągu 30 dni',
  '_xbb._aqyys': 'Bezpieczeństwo i prywatność',
  '_xbb._wjtrxk': 'Bezpieczna płatność: Nie udostępnimy Twoich danych osobowych żadnym podmiotom trzecim bez Twojej zgody. ',
  '_xbb._sfgrxx': 'Bezpieczne dane osobowe: Chronimy Twoją prywatność i dbamy o bezpieczeństwo Twoich danych osobowych. ',
  '_xbb._sl': 'Ilość',
  '_xbb._kcld': 'Zapasy',

  '_xbb._sjrz': 'Kupiec osiadły',
  '_xbb._hezjh': 'Nasz program partnerski zapewnia pełny zakres wsparcia i usług marketingowych, a nasz zespół obsługi klienta zapewni profesjonalne wsparcie i porady, które pomogą Ci zoptymalizować prezentację produktu i strategię sprzedaży. Dołącz do nas teraz! Stwórzmy większe możliwości biznesowe i rozwijajmy się razem! ',
  '_xbb._xsje': 'Informacje biznesowe',
  '_xbb._rgsqjs': 'Jeśli jesteś już sprzedawcą',
  '_xbb._qni': 'Proszę',
  '_xbb._djdl': 'Kliknij aby się zalogować',
  '_xbb._dplog': 'Zapisz LOGO',
  '_xbb._dpmc': 'Nazwa sklepu',
  '_xbb._srdpmc': 'Proszę podać nazwę sklepu',
  '_xbb._zjhm': 'Numer identyfikacyjny',
  '_xbb._srzjhm': 'Proszę podać numer identyfikacyjny',
  '_xbb._zsxm': 'prawdziwe imię',
  '_xbb._srzsxm': 'Proszę podać swoje prawdziwe imię i nazwisko',
  '_xbb._srgj': 'Kraj',
  '_xbb._srsrgj': 'Proszę podać kraj',
  '_xbb._zjz': 'Zdjęcie dokumentowe',
  '_xbb._zjzm': 'Przód dokumentu',
  '_xbb._zjfm': 'Odwrotna strona certyfikatu',
  '_xbb._sczjz': 'Zatrzymaj zdjęcie identyfikacyjne',
  '_xbb._pssl': 'Przykład fotografii',
  '_xbb._dlyx': 'E-mail logowania',
  '_xbb._srdlyx': 'Proszę podać swój adres e-mail do logowania',
  '_xbb._dlmm': 'Hasło logowania',
  '_xbb._srdlmm': 'Proszę podać hasło do logowania',
  '_xbb._qrmm': 'Potwierdź hasło',
  '_xbb._srqrmm': 'Wprowadź hasło potwierdzające',
  '_xbb._yqm': 'kod zaproszenia',
  '_xbb._sryqm': 'Wpisz kod zaproszenia',
  '_xbb._yxyzm': 'Kod weryfikacyjny e-mail',
  '_xbb._sryzm': 'Wprowadź kod weryfikacyjny adresu e-mail',
  '_xbb._yydbty': 'Przeczytałem i zgadzam się',
  '_xbb._rzxy': 'Ugoda',

  '_xbb._qgxty': 'Proszę dokładnie przeczytać ugodę i zaznaczyć pole, aby wyrazić zgodę',
  '_xbb._sryqmb': 'Wprowadź kod zaproszenia',

  '_xbb._ddpl': 'Uwagi do zamówienia',
  '_xbb._dsppl': 'Komentarze do produktów',
  '_xbb._dddpl': 'Skomentuj zamówienie'
}
