<template>
  <!-- 内容 -->
  <StoreHead>
    <template v-slot:banner="{ banner }">
      <img v-if="banner != ''" :src="banner + '?r=' + Math.floor(Math.random() * 1000)" alt="" style="width: 100%; min-height: 80px; max-height: 150px" />
      <div v-else style="color: #fff; min-height: 80px">
        <img :src="require('../../static/temp/banner/b1.jpg')" alt="" style="width: 100%; min-height: 80px; max-height: 150px" />
      </div>
    </template>
    <template #content>
      <div id="content">
        <div id="bd">
          <div id="bd-inner">
            <!-- 导航标签 -->
            <div class="layout grid-c">
              <div class="col-main">
                <div class="main-wrap" data-modules="main" data-width="h950">
                  <div class="module m-sop m-sop-crumb" data-spm="100004">
                    {{ $t('detail.store.home') }}
                    &gt; {{ $t('_sjsy._ddpl') }}
                  </div>
                </div>
              </div>
            </div>

            <div class="detail-page layout row-c2-s7" id="extend">
              <div class="col-main">
                <a-spin tip="Loading..." :spinning="spinning">
                  <a-empty :image="simpleImage" v-show="list.length == 0" />
                  <div class="pl-main">
                    <div class="pl-box" v-for="(item, i) in list" :key="i">
                      <!-- 左边 -->
                      <div class="pl-head">
                        <div class="hd">
                          <img :src="item.avatar" alt="avatar" style="width: 35px; height: 35px" />
                        </div>
                        <div class="mz">{{ item.nickname }}</div>
                      </div>
                      <!-- 右边 -->
                      <div>
                        <div class="pl-order">{{ $t('store.order.no') }}：{{ item.order_sn }}</div>

                        <div class="pl-star">
                          <a-rate :value="item.star" disabled style="font-size: 14px" />
                          <div>{{ item.star }}</div>
                        </div>
                        <div class="pl-comment">{{ item.comment }}</div>
                        <div class="pl-imgs-box">
                          <div class="pl-ibox" v-for="(vo, ii) in item.detail" :key="ii">
                            <a-image :src="vo.img" />
                          </div>
                          <template v-if="item.imgs && item.imgs.length > 0">
                            <div class="pl-ibox" v-for="(vo, ii) in item.imgs" :key="ii">
                              <a-image :src="vo" />
                            </div>
                          </template>
                        </div>
                        <div>{{ item.create_time }}</div>
                      </div>
                    </div>
                    <div style="text-align: right">
                      <a-pagination v-model:current="page" :total="total" show-less-items @change="onPageChange" />
                    </div>
                  </div>
                </a-spin>
              </div>
            </div>
          </div>
        </div>
      </div>

      <GoTop></GoTop>
    </template>
  </StoreHead>
</template>

<script>
import GoTop from '@/components/GoTop.vue'
import StoreHead from '@/components/StoreHead.vue'
import { Empty } from 'ant-design-vue'
export default {
  name: 'storeVue',
  components: { GoTop, StoreHead },
  data() {
    return {
      store_id: 0,

      //加载更多
      list: [],
      page: 1,
      size: 8,
      total: 0,

      spinning: true,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE
    }
  },
  created() {
    this.store_id = this.$route.query.id
    this.getStoreOrderCommentList()
  },
  methods: {
    onPageChange(page) {
      this.page = page
      this.getStoreOrderCommentList()
    },
    getStoreOrderCommentList() {
      this.spinning = true
      this.list = []
      let that = this
      this.$api.storeInfo
        .getStoreOrderCommentList({
          id: this.store_id,
          page: this.page,
          size: this.size
        })
        .then((res) => {
          that.total = res.data.total
          that.list = res.data.data
          that.spinning = false
        })
    }
  }
}
</script>
<style scoped src="@/static/css/store-index.css"></style>
<style scoped src="@/static/css/newindex.css"></style>
<style scoped src="@/static/css/storesell.css"></style>
<style scoped>
#content {
  background-color: #fff;
}

/* #content {
    background: -webkit-gradient(linear,0 0,0 100%,from(#c4e8e8),color-stop(.33,#fff),color-stop(.67,#fff),to(#fff));
    background: -webkit-linear-gradient(#c4e8e8,#fff,#fff,#fff);
    background: -moz-linear-gradient(#c4e8e8,#fff,#fff,#fff);
    background: -o-linear-gradient(#c4e8e8,#fff,#fff,#fff);
    background: linear-gradient(#c4e8e8,#fff,#fff,#fff)
} */

.official-content {
  background-color: inherit !important;
  background-image: inherit !important;
  background-repeat: inherit !important;
}

#hd .layout {
  background-color: #fff;
}

#bd {
  background-color: #fff;
}

.ui-box-wrap .ui-box-title {
  background-color: #b2eced;
}

.m-sop-shop-nav {
  background-color: #40b1b6;
}

.m-sop-discount {
  background-color: rgba(178, 232, 233, 0.3);
}

.m-sop-custom h3 {
  background-color: #b2eced;
}

.ae-m-sop-seller-coupon h2 {
  background-color: #b2eced;
}

.loadmore-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-box {
  box-shadow: 0px 0px 8px 0px #e3e3e3;
  border-radius: 5px;
}

.ibox-main {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

.pl-main {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-top: 50px;
}

.pl-box {
  display: flex;
  padding-bottom: 20px;
  border-bottom: 1px dashed #ccc;
}

.pl-head {
  display: flex;
  align-content: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0 10px 10px;
  width: 70px;
  gap: 10px;
}

.pl-head .hd {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pl-head .mz {
  word-wrap: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}
.pl-order {
  font-size: 14px;
  font-weight: 700;
}

.pl-star {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding: 5px 0;
}

.pl-comment {
  padding: 10px 0;
  font-size: 14px;
}

.pl-imgs-box {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px 0;
}
.pl-ibox {
  width: 80px;
  height: 80px;
  overflow: hidden;
}
.pl-imgs {
}


</style>
