<template>
  <div class="es--wrap--RYjm1RT down-box" v-if="isLogin">
    <div>
      <div class="ship-to--menuItem--WdBDsYl">
        <span :class="['ship-to--cssFlag--3qFf5C9', '-country-flag-y2023']"></span>
        <comment-outlined style="color: var(--nav-color1); font-size: 20px" />
        <div class="ship-to--text--3H_PaoC" style="display: flex">
          <span class="ship-to--small--1wG1oGl">{{ $t('base.server') }}</span>
          <b>
            <span class="comet-icon comet-icon-chevrondown32 ship-to--centerIcon--1viVSdj base--chevronIcon--25sHdop">
              <svg style="fill: var(--nav-color1)" viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                <path d="M296.256 354.944l224 224 224-224a74.656 74.656 0 0 1 0 105.6l-197.6 197.6a37.344 37.344 0 0 1-52.8 0l-197.6-197.6a74.656 74.656 0 0 1 0-105.6z"></path>
              </svg>
            </span>
          </b>
        </div>
        <!-- <span class="comet-icon comet-icon-chevrondown32 base--responseIcon--3et2x1Z">
          <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
            <path d="M296.256 354.944l224 224 224-224a74.656 74.656 0 0 1 0 105.6l-197.6 197.6a37.344 37.344 0 0 1-52.8 0l-197.6-197.6a74.656 74.656 0 0 1 0-105.6z"></path>
          </svg>
        </span> -->
      </div>
    </div>

    <!-- 下拉框 -->
    <div class="comet-v2-popover comet-v2-popover-show-arrow show-box" data-popper-placement="bottom-start" style="position: absolute; inset: 45px 0 auto auto; margin: 0px">
      <div class="comet-v2-popover-arrow" data-popper-arrow="true" style="position: absolute; right: 81px"></div>
      <div class="comet-v2-popover-body" style="border-radius: 24px">
        <div class="comet-v2-popover-content" style="padding: 20px 0">
          <div class="nav-show">
            <ul class="nav-show-list">
              <li class="langs-item">
                <router-link :to="{ name: sp_info.type == 1 ? 'message' : 'storeMessage', query: { customer: 'agent' } }" class="KCBwR lcFyR tuUGG RTmqg">
                  <div class="ng-item ng-mobile icon-ant" style="width: 100%">
                    <div style="display: flex; align-items: center; justify-content: center; gap: 10px">
                      <CommentOutlined style="font-size: 20px" />
                      <div style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden">{{ $t('_yhqd._lxkf') }}</div>
                    </div>
                  </div>
                </router-link>
              </li>
              <li class="langs-item">
                <a :href="sp_info.whatsapp ? 'https://wa.me/' + sp_info.whatsapp : 'javascript:'" :target="sp_info.whatsapp ? '_blank' : ''">
                  <div class="ng-item ng-mobile icon-ant" style="width: 100%">
                    <IconWhatsApp style="font-size: 18px" />
                    WhatsApp
                  </div>
                </a>
              </li>
              <li class="langs-item">
                <a :href="sp_info.telegram ? 'https://t.me/' + sp_info.telegram : 'javascript:'" :target="sp_info.telegram ? '_blank' : ''">
                  <div class="ng-item ng-mobile icon-ant" style="width: 100%">
                    <IconTelegram style="font-size: 18px" />
                    Telegram
                  </div>
                </a>
              </li>
              <li class="langs-item" style="position: relative">
                <a v-if="sp_info.line" :href="sp_info.line" :target="sp_info.line ? '_blank' : ''">
                  <div class="ng-item ng-mobile icon-ant" style="width: 100%">
                    <IconLine style="font-size: 20px" />
                    line
                  </div>
                </a>
                <div class="line-qrcode" v-else-if="sp_info.line_qrcode" style="display: flex; align-items: center">
                  <div class="ng-item ng-mobile icon-ant" style="width: 100%">
                    <IconLine style="font-size: 20px" />
                    Line
                    <div class="line-box">
                      <img :src="sp_info.line_qrcode + '?r=' + Math.random() * 1000" alt="line" />
                    </div>
                  </div>
                  <right-outlined style="font-size: 12px" />
                </div>
                <a v-else href="javascript:">
                  <div class="ng-item ng-mobile icon-ant" style="width: 100%">
                    <IconLine style="font-size: 20px" />
                    Line
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RightOutlined, CommentOutlined } from '@ant-design/icons-vue'
export default {
  components: { RightOutlined, CommentOutlined },
  name: 'HeadVue',

  data() {
    return {
      sp_info: {},
      isLogin: false
    }
  },
  created() {
    this.tokenStatus()
  },
  methods: {
    /**
     * 登录成功
     */
    tokenStatus() {
      this.isLogin = this.$common.isLogin()
      this.sp_info = this.$common.getInfo()
    }
  }
}
</script>

<style scoped src="@/static/css5/index.css"></style>
<style scoped>
.down-box {
  position: relative;
}

.down-box:hover .show-box {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
.show-box {
  transform: scale(0.8);
  opacity: 0;
  visibility: hidden;
  transition: all 0.13s ease-in-out;
  z-index: 10;
}

.nav-show {
  width: 160px;
}

.langs-item {
  list-style: none;
  cursor: pointer;
  height: 32px;
  line-height: 32px;
  width: 100%;
  padding: 0px 20px;
}

.langs-item a {
  color: #000;
}
.langs-item:hover {
  background: #f6f6f6;
}

.line-qrcode:hover .line-box {
  opacity: 1;
  visibility: visible;
}

.line-box {
  opacity: 1;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 100%;
  width: 200px;
  height: 200px;
  border-radius: 10px;
  overflow: hidden;
}
</style>

<style></style>
