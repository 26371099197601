<template>
  <div>
    <div class="comet-modal-mask comet-fade-appear-done comet-fade-enter-done comet-fade-enter comet-fade-enter-active" v-if="isShow"></div>
    <div tabindex="-1" class="comet-modal-wrap" v-if="isShow">
      <div class="comet-modal comet-zoom-appear-done comet-zoom-enter-done" style="width: 918px">
        <div tabindex="0" aria-hidden="true" style="width: 0px; height: 0px; overflow: hidden; outline: none"></div>
        <button @click="onClose" type="button" aria-label="Close" class="comet-modal-close">
          <span class="comet-icon comet-icon-close">
            <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
              <path d="M181.088 135.936l1.536 1.44L512 466.741333l329.376-329.365333a32 32 0 0 1 46.688 43.712l-1.44 1.536L557.258667 512l329.365333 329.376a32 32 0 0 1-43.712 46.688l-1.536-1.44L512 557.258667 182.624 886.624a32 32 0 0 1-46.688-43.712l1.44-1.536L466.741333 512 137.376 182.624a32 32 0 0 1 43.712-46.688z"></path>
            </svg>
          </span>
        </button>
        <div class="comet-modal-content comet-modal-no-footer">
          <div class="comet-modal-header">
            <div class="comet-modal-title comet-modal-title-left">
             {{$t('_xbb._dddpl')}}
            </div>
          </div>
          <div class="comet-modal-body">
            <div class="order-add-cart">
              <div class="order-add-cart-tip">
                <a-form :model="formState" v-bind="layout" name="nest-messages" :validate-messages="validateMessages" @finish="onFinish">
                  <a-form-item label="Star">
                    <a-rate v-model:value="formState.star" aallow-half />
                  </a-form-item>
                  <a-form-item label="comment">
                    <a-textarea :rows="4" v-model:value="formState.comment" />
                  </a-form-item>
                  <!-- 商品多张图 -->
                  <a-form-item label="picture">
                    <a-upload v-model:file-list="imgs" action="/web/v1/upload/file" list-type="picture-card" @preview="onImgsPreview" @change="onImgsChange">
                      <div v-if="imgs.length < 6">
                        <loading-outlined v-if="loadingImageMore"></loading-outlined>
                        <plus-outlined v-else></plus-outlined>
                        <div style="margin-top: 8px">Upload</div>
                      </div>
                    </a-upload>
                    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                      <img alt="example" style="width: 100%" :src="previewImage" />
                    </a-modal>
                  </a-form-item>
                  <a-form-item :wrapper-col="{ ...layout.wrapperCol, offset: 11 }">
                    <a-button type="primary" html-type="submit" :loading="btnLoad">Comment</a-button>
                  </a-form-item>
                </a-form>
              </div>
            </div>
          </div>
        </div>
        <div tabindex="0" aria-hidden="true" style="width: 0px; height: 0px; overflow: hidden; outline: none"></div>
      </div>
    </div>
  </div>
</template>

<script>
const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons-vue";
export default {
  name: "CountryVue",
  components: {
    LoadingOutlined,
    PlusOutlined,
  },
  data () {
    return {
      //当前参数
      id: 0,

      //显示
      isShow: false,

      //表单
      formState: {
        star: 0,
        comment: "",
      },
      //多图
      loadingImageMore: false,
      previewVisible: false,
      imgs: [],

      //按钮
      btnLoad: false,

      //布局
      layout,
    };
  },
  methods: {
    open (id) {
      this.id = id;
      this.btnLoad = false;
      this.isShow = !this.isShow;
    },
    hide () {
      this.isShow = false;
    },
    onClose () {
      this.open();
    },
    onFinish () {
      this.btnLoad = true;

      //参数
      this.formState["order_id"] = this.id;
      this.formState["imgs"] = this.imgs;
      console.log(this.formState);

      //提交评论
      let that = this;
      this.$api.order.orderComment(this.formState).then((res) => {
        that.$message.success(res.msg);
        setTimeout(() => {
          that.hide();
          that.$emit("onOk");
        }, 500);
      });
    },
    /**
     * 上传多张商品图
     * @param {*} file
     */
    getImgsBase64 (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    // 取消预览
    handleCancel () {
      this.previewVisible = false;
    },
    // 开始预览
    async onImgsPreview (file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getImgsBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    onImgsChange (info) {
      const { file, fileList } = info;
      console.log("上传", info, JSON.stringify(file));
      if (file.status === "uploading" && file.percent === 0) {
        this.loadingImageMore = true;
        console.log("开始上传", file.name);
      }
      if (file.status === "uploading" && file.percent === 100) {
        this.loadingImageMore = true;
        console.log("上传100%", file.name);
      }
      if (file.status === "removed") {
        console.log("删除图片", file.name);
        if (file.id > 0) {
          this.rmimgs.push(file.id);
        }
      }
      if (file.status === "done") {
        this.loadingImageMore = false;
        var response = file.response;
        if (response.code === 1) {
          this.$message.error(response.msg);
          this.$vue.delete(this.imgs, this.imgs.length - 1, 1);
          return;
        }
        console.log("上传成功", file.name);
      }

      const imgs = [];
      fileList.forEach((item) => {
        const temp = {
          id: 0,
          uid: item.uid,
          name: item.name,
          status: item.status,
          url: "",
        };
        if (item.response) {
          temp.url = item.response.data.url;
        }
        if (item.url) {
          temp.url = item.url;
        }
        if (item.id) {
          temp.id = item.id;
        }
        imgs.push(temp);
      });

      this.imgs = [...imgs];
      console.log(this.imgs, this.rmimgs, "imgs");
    },
    imgsChange (file) {
      const temp = {
        uid: file.uid,
        name: file.name,
        status: file.status,
      };
      if (file.response) {
        temp["url"] = file.response.data.url;
      }
      return temp;
    },
  },
};
</script>
