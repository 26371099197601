export default {
  'nav.account': '個人中心',
  'nav.language': '簡體中文',
  'signup.success': '註冊成功',

  //error
  'contact.empty': '聯繫方式不能為空',
  'phone.country.empty': '電話前綴不能為空',
  'phone.empty': '電話不能為空',
  'email.valid': '請輸入正確的郵箱',

  // base
  'base.success': '成功',
  'base.save': '保存',
  'base.slide': '請拉動滑條驗證',
  'base.send.code': '發送驗證碼',
  'base.send.time': '({time}) 重發驗證碼',
  'base.send.success': '發送成功',
  'base.view.more': '瀏覽更多',
  'base.Language': '語言',
  'base.previous': '上一頁',
  'base.next': '下一頁',
  'base.total.page': '共 {page} 頁',
  'base.go.to.page': '轉到頁面',
  'base.go': '前往',
  'base.followers': '關注量',
  'base.follow': '關注',
  'base.following': '已關注',
  'base.visit.store': '瀏覽店鋪',
  'base.contact': '聯繫我們',
  'base.delivery': '預計交貨時間:1-7 天',
  'base.search': '搜索',
  'base.search.text': '輸入關鍵字搜索',
  'base.server': '在線客服',

  //index
  'text.categories.title': '商品分類',
  'text.welcome': '歡迎購物!',
  'text.wishlist': '喜歡列表',
  'text.account': '個人中心',
  'text.orders': '訂單',
  'text.messages': '消息',
  'text.suggest.title': '獨家優惠',
  'text.suggest.content': '只為新的全球至尊會員!',
  'text.super.deals': '頂級產品,令人難以置信的價格.',

  //signup
  'signup.register': '註冊',
  'signup.signin': '登錄',
  'signup.store.signin': '商家登錄',
  'signup.sign.out': '註銷',
  'signup.email': '郵箱地址',
  'signup.password': '密碼',
  'signup.qr.password': '確認密碼',
  'signup.confirm.password': '確認密碼',
  'signup.forgot.password': '忘記密碼',
  'signup.invitation': '邀請碼',
  'signup.char': '字符',
  'signup.contains': '包含數字、字母或符號',
  'signup.qr.contains': '兩次密碼不一致',
  'signup.create.account': '創建賬戶',
  'signup.agree': '創建帳戶，則同意',
  'signup.member.agreement': '會員協議',
  'signup.and': '與',
  'signup.privacy.policy': '隱私政策',
  'signup.email.code': '郵箱驗證',
  'signup.last.step': '最後一步',
  'signup.send.email': '請輸入發送至{email}的4位數代碼',
  'signup.modify.email': '修改郵箱',
  'signup.verify.email': '驗證郵箱',
  'signup.have.store': '有商家賬號',
  'signup.goto.signin': '商家登錄',
  'signup.no.store': '沒有商家賬號',
  'signup.goto.store': '註冊商家',
  'signup.next': '下一步',
  'signup.your.email': '你的郵箱',
  'signup.code.text': '驗證碼',
  'signup.submit.signup': '立即註冊',
  'signup.smrz': '實名認證',
  'signup.derb': '第二步',
  'signup.qsrxm': '請輸入證件姓名',
  'signup.qsrhm': '請輸入證件號碼',

  //forgot
  'forgot.title': '重置密碼',
  'forgot.btn.check': '驗證郵箱',
  'forgot.reset.now': '立即重置',

  //store
  'store.info.open': '開店{y}週',

  // footer
  'footer.great.value': '巨大的價值',
  'footer.great.value.desc': '我們為超過1億件商品提供具有競爭力的價格.',
  'footer.shopping': '全球購物',
  'footer.shopping.desc': '我們向200多個國家和地區發貨,我們的網站提供7種語言.',
  'footer.safe.payment': '安全支付',
  'footer.safe.payment.desc': '使用世界上最流行和最安全的支付方式進行支付.',
  'footer.shop.with.confidence': '放心購物',
  'footer.shop.with.confidence.desc': '我們的買家保護政策涵蓋您的整個購買過程.',
  'footer.help.center': '幫助中心',
  'footer.help.center.desc': '全天候協助,打造順暢的購物體驗.',
  'footer.terms.conditions': '條款和條件',
  'footer.return.policy': '退貨政策',
  'footer.support.policy': '支持政策',
  'footer.privacy.policy': '隱私政策',
  'footer.be.seller': '成為商家',
  'footer.apply.now': '立即申請',
  'footer.stay.connected': '保持聯繫',

  'footer.about.us': '關於我們',
  'footer.about.company': '公司簡介',
  'footer.about.video': '視頻介紹',
  'footer.contact': '聯繫方式',

  'footer.my.account': '我的賬戶',
  'footer.my.logout': '退出登錄',
  'footer.my.order': '訂單歷史',
  'footer.my.wish': '我的願望清單',
  'footer.my.join': '成為會員合作夥伴',
  'footer.email': '電子郵箱',
  'footer.gfemail': '官方郵箱',
  'footer.fwemail': '服務郵箱',
  'footer.address': '地址',

  'apply.success': '申請成功',
  'apply.success.desc': '已申請成功,前往登錄賬戶',

  // categories
  'category.title': '相關分類',
  'category.all': '所有分類',

  // detail
  'detail.store.home': '店舖首頁',
  'detail.sale.items': '商品列表',
  'detail.recommend': '推薦',
  'detail.orders': '銷量',
  'detail.quantity': '數量',
  'detail.pieces.available': '可用',
  'detail.delivery': '送貨',
  'detail.free.shipping': '免運費',
  'detail.estimated.delivery': '預計到達',
  'detail.days': '天',
  'detail.buy.now': '購買',
  'detail.add.to.cart': '加入購物車',
  'detail.buyer.protection': '買家保護',
  'detail.money.guarantee': '退款保證',
  'detail.refund.desc': '如果物品與描述不符或未送達，可獲得全額退款',
  'detail.description': '商品描述',
  'detail.customer.reviews': '顧客評論',
  'detail.specifications': '商品規格',
  'detail.top.selling.products': '暢銷商品',
  'detail.recommended.for.you': '為你推薦',
  'detail.sold': '銷量',
  'detail.receipt': '確認收貨',
  'detail.receipt.title': '確認收到訂單？',
  'detail.receipt.content': '確認後，交易訂單完成',
  'detail.comment': '評論',
  'detail.refund.title': '确认申请退款',
  'detail.refund.content': '确认后，订单将申请退款',
  'detail.sqtk': '申请退款',

  // confirm
  'confirm.shipping.address': '收件地址',
  'confirm.change': '改變',
  'confirm.payment.methods': '支付方式',
  'confirm.summary': '結算',
  'confirm.total.item.costs': '商品合計',
  'confirm.total': '總計',
  'confirm.checkout': '去結算',
  'confirm.place.order': '立即下單',
  'confirm.pay.now': '立即支付',
  'confirm.order.desc': "單擊'下訂單'後，我確認我已閱讀並確認",
  'confirm.order.policy': '所有條款和政策',
  'confirm.payment': '全球購物確保您的信息和付款安全',

  // address
  'address.title': '收件地址',
  'address.default': '默認',
  'address.edit': '編輯',
  'address.delete': '刪除',
  'address.new': '創建新地址',
  'address.contact': '聯繫人',
  'address.address': '地址',
  'address.phone': '電話號碼',
  'address.set.default': '設置默認',
  'address.confirm': '確認',
  'address.cancel': '取消',
  'address.del.title': '確認刪除地址?',
  'address.del.content': '您確定要刪除此送貨地址嗎?',

  'payment.method': '支付方式',

  // Shopping Cart
  'shopping.title': '購物車',
  'shopping.back.buy': '返回特價購物',
  'shopping.start.shopping': '開始購物',

  // member
  'member.account': '個人中心',
  'member.overview': '概述',
  'member.orders': '訂單',
  'member.payment': '我的錢包',
  'member.address': '收貨地址',
  'member.wishlist': '喜歡列表',
  'member.followlist': '關注列表',
  'member.message.center': '消息中心',
  'member.setting': '個人設置',
  'member.shop.info': '商舖信息',
  'member.shop.setting': '商舖設置',
  'member.order.notify': '新訂單通知',
  'member.order.tips': '通過發送郵件到商家賬號郵箱',

  // member.order
  'member.order.title': '我的訂單',
  'member.order.view.all': '查看全部',
  'member.order.all': '全部',
  'member.order.unpaid': '未支付',
  'member.order.paid': '已支付',
  'member.order.procurement': '待採購',
  'member.order.seller.paid': '已採購',
  'member.order.processing': '待處理',
  'member.order.shipped': '配送中',
  'member.order.return': '退貨中',
  'member.order.completed': '已完成',
  'member.order.refund': '已退款',
  'member.order.all.time': '全部',
  'member.order.empty': '暫無訂單',
  'member.order.date': '日期',
  'member.order.purchase.date': '採購',
  'member.order.cpoy': '複製',
  'member.order.id': '訂單',
  'member.order.detail': '訂單詳情',
  'member.order.logistics': '物流信息',

  // member.payment
  'member.payment.title': '我的錢包',
  'member.wallet.balance': '錢包餘額',
  'member.crypto.recharge': '充值',
  'member.crypto.withdrawal': '提現',
  'member.crypto.bank': '銀行卡',
  'member.wallet.record': '存幣記錄',
  'member.bankcard.record': '銀行卡記錄',
  'member.withdrawal.record': '提現記錄',
  'member.income.record': '收益記錄',
  'member.transaction.record': '交易記錄',
  'member.wallet.freeze': '凍結資金',
  'member.wallet.profit': '預計收益',

  //recharge
  'recharge.currency': '幣種',
  'recharge.protocol': '協議',
  'recharge.qrcode': '二維碼',
  'recharge.address': '錢包地址',
  'recharge.copy': '複製地址',
  'recharge.ok.text': '確認',
  'recharge.cancel.text': '取消',

  //bank
  'bank.recharge.title': '銀行卡充值',
  'bank.title': '銀行名稱',
  'bank.name': '姓名',
  'bank.account': '帳戶',
  'bank.routing': '路由',
  'bank.code': '代碼',
  'bank.bankname': '銀行名稱',
  'bank.bankaddress': '銀行地址',
  'bank.ok.text': '下一步',
  'bank.ok.prev': '上一步',
  'bank.submit': '立即提交',
  'bank.amount': '充值金額',
  'bank.amount.desc': '請輸入充值金額',
  'bank.type': '類型',
  'bank.usd': '美元',
  'bank.eur': '歐元',
  'bank.receipt.number': '回執單號',
  'bank.receipt.number.desc': '請輸入交易回執單號',
  'bank.credential.picture': '憑證圖片',
  'bank.credential.picture.desc': '請上傳憑證圖片',
  'bank.remark': '說明',
  'bank.upload': '上傳圖片',
  'bank.text.title': '注意事項',
  'bank.text.t1': '轉賬不要任何備註,不填',
  'bank.text.t2': '香港戶國家選擇香港,不要加中國',
  'bank.text.t3': '美國匯款,工作日,下午三點前匯歐洲匯款,銀行上班時間可匯!',
  'bank.text.t4': '到款時間T+1,歐洲最遲T+3到款',
  'bank.text.t5': '轉款之前告知平台,確定賬戶是否可用,如果封戶，不賠付.',
  'bank.text.t6': '通過銀行卡入金一切已客服信息為準.',

  //banklist
  'bank.list.title': '銀行名稱',
  'bank.list.code': '銀行代碼',
  'bank.list.amount': '轉賬金額',
  'bank.list.number': '回執單號',
  'bank.list.img': '憑證圖片',
  'bank.list.status': '狀態',
  'bank.list.time': '時間',
  'bank.list.status1': '審核中',
  'bank.list.status2': '已通過',
  'bank.list.status3': '已駁回',

  // Withdrawal
  'withdrawal.address.desc': '請輸入錢包地址!',
  'withdrawal.number': '數量',
  'withdrawal.real.number': '實際到賬',
  'withdrawal.number.desc': '請輸入提現數量!',
  'withdrawal.btn.all': '全部',
  'withdrawal.balance': '餘額',
  'withdrawal.commission': '佣金',
  'withdrawal.actual.amount': '到賬',
  'withdrawal.notice': '提示',
  'withdrawal.notice.text': '轉賬前,請確認收款地址信息正確無誤.資產一經轉出,不可退回.',
  'withdrawal.notice.content': '{name}({cp_name}) 佣金:當前市值{fee_rate}%/筆,最低標準:{fee_min} {name}/筆',
  'withdrawal.submit': '提交',
  'withdrawal.choice': '選擇加密貨幣',
  'withdrawal.yzm': '驗證碼',
  'withdrawal.fs': '發送',
  'withdrawal.qsryzm': '請輸入郵箱驗證碼',

  // recive
  'recive.method': '協議',
  'recive.amount': '金額',
  'recive.address': '地址',
  'recive.date': '時間',
  'recive.status': '狀態',
  'recive.create.at': '交易時間',
  'recive.type': '類型',
  'recive.befor': '交易之前',
  'recive.balance': '餘額',
  'recive.freeze': '凍結',
  'recive.review': '審核中',
  'recive.success': '已通過',
  'recive.reject': '已駁回',

  // advertise
  'advertise.title': '推廣中心',
  'advertise.shop.title': '店鋪推廣',
  'advertise.shop.status': '狀態',
  'advertise.shop.expired': '已過期',
  'advertise.shop.promotion': '推廣中',
  'advertise.shop.expire.date': '到期時間',
  'advertise.shop.renew': '廣告續費',
  'advertise.shop.payable': '應付金額',
  'advertise.shop.explanation': '推廣說明',
  'advertise.shop.text': '參與平台廣告推廣，提升店鋪曝光度，促進交易訂單量',
  'advertise.shop.paynow': '立即續費',
  'advertise.shop.modal.title': '確認支付',
  'advertise.shop.modal.desc': '確認為支付推廣費用',
  'advertise.shop.modal.btn': '確認支付',

  //Wish List
  'wishlist.title': '喜歡列表',
  'wishlist.delete': '刪除',
  'wishlist.orders': '銷量',

  //Follow List
  'followlist.title': '關注列表',
  'followlist.delete': '刪除',
  'followlist.follow': '關注',

  // store
  'store.dashboard': '儀錶盤',
  'store.products': '產品',
  'store.products.list': '產品列表',
  'store.products.reviews': '產品回复',
  'store.orders': '訂單',
  'store.wallet': '錢包',
  'store.message': '消息中心',
  'store.setting': '設置',
  'store.order.total.profit': '預計營業總收益',

  //dashboard
  'dashboard.store.hour.views': '實時訪問量',
  'dashboard.store.today.views': '今日訪問量',
  'dashboard.product.total': '產品總量',
  'dashboard.product.today': '今日新增',
  'dashboard.order.total': '訂單總量',
  'dashboard.sales.total': '預計營業額',
  'dashboard.sales.real': '實際營業額',
  'dashboard.sales.pay': '總支出',
  'dashboard.sales.profit': '總收益',
  'dashboard.commission.total': '總收益',
  'dashboard.commission.today': '今日收益',
  'dashboard.order.sales': '商品銷售量',
  'dashboard.sales.list': '銷售排行',
  'dashboard.goods.cate.rate': '商品分類占比',
  'dashboard.goods.wish': '喜歡商品排行',
  'dashboard.january': '1月',
  'dashboard.february': '2月',
  'dashboard.march': '3月',
  'dashboard.april': '4月',
  'dashboard.may': '5月',
  'dashboard.june': '6月',
  'dashboard.july': '7月',
  'dashboard.august': '8月',
  'dashboard.september': '9月',
  'dashboard.october': '10月',
  'dashboard.november': '11月',
  'dashboard.december': '12月',

  // products
  'products.add.new': '添加新產品',
  'products.add.from.warehouse': '從倉庫添加產品',
  'products.delete': '刪除',
  'products.add': '添加',
  'products.table.img': '圖片',
  'products.table.name': '商品名稱',
  'products.table.category': '所屬分類',
  'products.table.wish': '喜歡',
  'products.table.stock': '庫存',
  'products.table.price': '價格',
  'products.table.profit': '收益',
  'products.table.action': '操作',
  'products.search.category': '按分類搜索',
  'products.back.product': '返回產品列表',
  'products.total': '商品總計',
  'products.yes': '是的',
  'products.no': '取消',
  'products.batch.add': '批量增加',
  'products.ask.add': '確定要增加商品嗎?',
  'products.batch.delete': '批量刪除',
  'products.ask.delete': '確定要刪除商品嗎?',
  'products.top': '置頂',
  'products.syzd': '首页置顶',
  'products.zdwz': '置顶位置',
  'products.t1': '推广',
  'products.t2': '优选',
  'products.t3': '精品',
  'products.t4': '无',

  // reviews
  'reviews.title': '產品評論',
  'reviews.product.name': '商品',
  'reviews.user.name': '用戶',
  'reviews.star': '評分',
  'reviews.comment': '評論內容',
  'reviews.sku': '商品規格',
  'reviews.imgs': '圖集',
  'reviews.created': '日期',

  // store.order
  'store.order.purchase': '立即採購',
  'store.order.purchase.desc': '確定要付款採購該產品?',
  'store.order.purchase.yes': '立即支付',
  'store.order.purchase.no': '取消',
  'store.order.desc': '採購該訂單需要支付貨款，訂單完成後獲得收益。',
  'store.order.no': '訂單號',
  'store.order.number': '數量',
  'store.order.buyer': '買家',
  'store.order.total': '總金額',
  'store.order.will.earning': '收入',
  'store.order.profit': '收益',
  'store.order.dividends': '分紅',
  'store.order.payment.status': '支付狀態',
  'store.order.seller.buy.status': '採購狀態',
  'store.order.status': '訂單狀態',
  'store.order.date': '訂單日期',
  'store.order.purchase.date': '採購日期',
  'store.order.action': '操作',
  'store.order.purchase.amount': '採購金額',

  //income
  'income.create.at': '記錄時間',
  'income.order.sn': '訂單編號',
  'income.realpay': '訂單金額',
  'income.profit': '收益',

  //Setting
  'setting.avatar': '商家頭像',
  'setting.upload': '上傳',
  'setting.shop.name': '店鋪名稱',
  'setting.shop.phone': '聯繫電話',
  'setting.shop.address': '店鋪地址',
  'setting.shop.save': '保存',
  'setting.upload.pic': '上傳圖片',
  'setting.send.pic': '發送圖片',

  //beseller
  'beseller.title': '申請成為商家',
  'beseller.account': '賬號信息',
  'beseller.store': '店鋪信息',
  'beseller.store.name': '店鋪名稱',
  'beseller.store.address': '店鋪地址',

  //savehome
  'store.home.title': '首頁設置',
  'store.home.topimg': '頂部圖',
  'store.home.banner': '輪播圖',
  'store.home.up3': '備註：最少上傳3張',
  'store.upload.more': '上傳多圖',
  'store.home.columns': '欄目',
  'store.home.bgimg': '背景圖片',
  'store.goods.remark': '備註：每行5個商品，每個板塊最多10個商品',
  'store.home.select': '選擇商品',
  'store.home.add': '增加欄目',

  //個人信息
  'setting.update.user': '個人信息',
  'setting.update.info': '修改信息',
  'setting.user.avatar': '用戶頭像',
  'setting.user.nickname': '用戶暱稱',
  'setting.user.nickname.desc': '請輸入用戶暱稱',

  'setting.safe.title': '安全信息',
  'setting.user.passwd': '修改密碼',
  'setting.passwd.title1': '舊密碼',
  'setting.passwd.desc1': '請輸入舊密碼',
  'setting.passwd.title2': '新密碼',
  'setting.passwd.desc2': '請輸入新密碼',
  'setting.passwd.title3': '確認密碼',
  'setting.passwd.desc3': '請輸入確認密碼',
  'setting.invitation': '邀請朋友',
  'setting.copy': '複製',

  'adv.add.products': '增加推廣商品',
  'adv.list.title': '商品推廣',
  'adv.type': '推廣類型',
  'adv.begin.time': '推廣時間',
  'adv.end.time': '結束時間',
  'adv.status': '狀態',
  'adv.status.s1': '推廣中',
  'adv.status.s2': '已結束',
  'adv.add.back': '返回已推廣列表',
  'adv.recharge': '充值推廣點數',
  'adv.select.goods': '選擇商品',
  'adv.select.btn': '推廣商品',
  'adv.recharge.title': '充值推廣點數',
  'adv.recharge.balance': '點數餘額',
  'adv.point': '點',
  'adv.point.rate': '點數比例',
  'adv.recharge.amount': '充值金額',
  'adv.recharge.input.amount': '輸入充值金額',
  'adv.select.mod': '選擇板塊',
  'adv.mod1': '推薦欄目',
  'adv.mod2': '優選欄目',
  'adv.mod3': '熱銷欄目',
  'adv.mod4': '精品列表',
  'adv.mod5': '分類列表',
  'adv.mod6': '詳情推廣',
  'adv.ge': '數量',
  'adv.xs': '小時',
  'adv.xzbk': '選擇板塊',
  'adv.syme': '剩餘名額',
  'adv.tgsc': '推廣時長',
  'adv.tgjg': '推廣價格',
  'adv.syye': '當前餘額',
  'adv.tgye': '推廣餘額',
  'adv.yj1': '預計 ',
  'adv.yj2': '開始推廣',
  'adv.pay.point': '消費點數',

  'wallet.ykcsxf': '已扣除手續費',
  'wallet.sxf': '手續費',
  'wallet.zxje': '最小金額',
  'wallet.24hxe': '24小時限額',
  'wallet.desc.text': '官方在任何情況下都不會要求您向某一賬戶轉賬，也不會向您索要驗證碼。請勿參與代買，洗錢、非法集資等違法行為，謹防網絡詐騙',
  'wallet.cunbi.text': '您只能向此地址充入{name},充入其他資產將無法找回',

  'credit.title': '信用額度',
  'credit.ed': '額度',
  'credit.hk': '還款',
  'credit.kyye': '可用餘額',
  'credit.dqqk': '當前欠款',
  'credit.hkje': '還款金額',
  'credit.hkje.desc': '請輸入還款金額',
  'credit.cgje': '採購金額',
  'credit.qrz': '去認證',
  'credit.srxyj': '輸入信用支付金額',

  'store.gzl': '關注量',
  'store.wxz': '無限制',

  'auth.smrz': '實名認證',
  'auth.rzlx': '類型',
  'auth.sfzm': '身份證正面',
  'auth.sffm': '身份證反面',
  'auth.zsxm': '真實姓名',
  'auth.zjhm': '證件號碼',
  'auth.yyzz': '營業執照',
  'auth.gsmc': '公司名稱',
  'auth.shz': '審核中',
  'auth.ytg': '已通過',
  'auth.ybh': '已駁回',
  'auth.zt': '狀態',
  'auth.gr': '個人',
  'auth.gs': '企業',
  'auth.ljtj': '申請認證',
  'auth.wrz': '未認證',

  'credit.qts': '查看說明',
  'store.spss': '商品上限',
  'store.dpfh': '店鋪分紅',
  'store.qydj': '權益等級',
  'store.level': '級',
  'store.jian': '件',

  'order.notify.email': '由於郵箱風控,平台訂單提醒郵件發送頻繁會被攔截,為了及時收到,建議將{e}添加到登錄郵箱白名單',

  'setting.sjxx': '商家信息',
  'setting.smrz': '實名認證',
  'setting.aqzx': '安全中心',
  'setting.tzxx': '通知信息',
  'setting.dpzx': '裝修首頁',
  'setting.sjqy': '商家權益',
  'setting.sjdjqy': '商家等級權益說明',

  'setting.fhbky': '當前分紅不可用',
  'setting.spgzyzy': '商舖規則與指引',

  'desc.dqqy': '當前權益',
  'desc.xjqy': '下級權益',
  'desc.sjqy_0': '店鋪信用額度',
  'desc.sjqy_1': '店鋪分紅',
  'desc.sjqy_2': '可發布商品數量',

  'share.yqlb': '邀請列表',
  'share.zmj': '總賣家',
  'share.zdd': '總訂單',
  'share.zsy': '經紀總收益',
  'share.wcl': '未處理',
  'share.ycl': '已處理',
  'share.ywc': '已完成',
  'share.dpmc': '店鋪名稱',
  'share.ddl': '訂單量',
  'share.jjsy': '經紀收益',
  'share.zts': '總條數',

  'chat.state': '狀態',
  'chat.zx': '在線',
  'chat.ljz': '連接中',

  'bind.wallet.title': '提幣地址',
  'bind.address.title': '添加地址',
  'bind.bj': '編輯',
  'bind.sc': '刪除',
  'bind.qrsc': '確定要刪除該地址?',
  'bind.qbdz': '錢包地址',
  'bind.tjsj': '添加時間',
  'bind.cz': '操作',
  'withdrawal.address.choice': '請選擇提現地址!',

  'order.sxsj': '生效時間',
  'order.ljsj': '立即生效',
  'order.dssj': '定時生效',

  'store.st.zt': '店铺状态',
  'store.st.zc': '正常',
  'store.st.zc.desc': '您的商铺一切正常，请继续保持',
  'store.st.yc': '异常',
  'store.st.yc.desc': '您的商铺存在部分的订单异常或收到客诉',
  'store.st.dj': '冻结',
  'store.st.dj.desc': '您的商铺存在一定的经营风险造成资金冻结',
  'store.st.jy': '禁用',
  'store.st.jy.desc': '您的商铺违返了平台相关协议与规则已禁用',

  'task.sy': '首页',
  'task.rwlbo': '任务列表',
  'task.rwxqo': '任务详情',
  'task.ljcy': '立即参与',
  'task.gdrw': '更多任务',
  'task.rwlb': '抢单任务列表',
  'task.qbrw': '全部任务',
  'task.wcyd': '我参与的',
  'task.qdz': '抢单中',
  'task.ywc': '已完成',
  'task.djy': '待交易',
  'task.ddje': '订单金额',
  'task.cyrs': '参与人数',
  'task.ewsy': '额外收益',
  'task.jzsj': '截止时间',
  'task.ckxq': '查看详情',
  'task.rwxq': '任务详情',
  'task.cylb': '参与列表',
  'task.qdcyrw': '确定要参与此订单任务吗?',
  'task.sd': '是的',
  'task.bue': '不',

  'task.wdsy': '收益',
  'task.wdfh': '分紅',
  'task.wdqd': '搶單',
  'task.dd.leixin': '訂單類型',
  'task.dd.qdrw': '搶單任務',
  'task.dd.ptdd': '普通訂單',

  'flow.lltg': '流量推廣',
  'flow.gmll': '購買流量套餐',
  'flow.dqtc': '當前套餐',
  'flow.tc1': '套餐',
  'flow.sy2': '剩餘',
  'flow.tian3': '天',
  'flow.jlzs': '記錄總數',
  'flow.lltc': '流量套餐',
  'flow.ll': '流量',
  'flow.gmsj': '購買時間',
  'flow.tcjg': '套餐價格',
  'flow.sjzf': '實際支付',
  'flow.tcsm': '套餐說明',
  'flow.tcsm.sm': '購買此套餐，將增加店舖的曝光率，獲得更大的客戶訪問流量。以此來獲得更多訂單，提高店鋪收益。 ',

  'flow.tcmc': '套餐名稱',
  'flow.gmsc': '購買時長',
  'flow.zje': '總金額',
  'flow.jzsj': '截止時間',
  'flow.gmrq': '購買日期',
  'flow.tian1': '天',
  'flow.zhou1': '週',
  'flow.yue1': '月',

  'share.dj': '等級',
  'share.hz': '劃轉',
  'share.hzd': '劃轉到',
  'share.qbye': '錢包餘額',
  'share.tgje': '推廣金額',
  'share.hzje': '劃轉金額',
  'share.qsrhzje': '請輸入劃轉金額',

  '_inv._title': '邀請說明',
  '_inv._t1': '1.邀請與被邀請關係',
  '_inv._desc1': 'A邀請B、B邀請C、C邀請D。最高4級,若D再邀請E, 則E與A無關。 ',
  '_inv._t2': '2.邀請人獲得獎勵',
  '_inv._desc2': 'A獲得B訂單完成總金額的4%，A獲得C訂單完成的總金額2%，A獲得D訂單完成的總金額1%',

  '_index._gm': '購買',
  '_index._sm': '說明：增加店鋪曝光率，吸引客流量',

  '_shop._update': '{n}次/月，當月已修改{m}次',
  '_chat._hc': '撤回',

  '_footer._suom': '政策說明',

  '_footer._platform._qualification': '公司資格',
  '_store._ptzz': 'ShopifyOutlets Global 平台相關資格',

  '_abt._gywm': '關於我們',
  '_abt._syms': '商業模式',
  '_abt._qyzr': '企業責任',
  '_abt._lxwm': '聯絡我們',

  '_wsj._wsjms': '在我們詭異的萬聖節商店裡以時尚的方式慶祝萬聖節。 無論您想在這個詭異的季節進行惡作劇還是款待，我們的萬聖節服裝、裝飾和配件系列都能為全家人留下一些東西。 用我們的女裝和男裝系列打造令人脊背發涼的宣言，或是用化裝服裝釋放孩子的想像。 從令人難忘的可愛家居用品和玩具，到迷人的美容和化妝品，找到完美的單品來慶祝一年中最令人興奮的節日。',

  '_wsj._b1': '秋季家居裝飾',
  '_wsj._b2': '南瓜',
  '_wsj._b3': '家',
  '_wsj._b4': '兒童的',
  '_wsj._b5': '寶貝的',
  '_wsj._b6': '女式',
  '_wsj._b7': '化裝舞會',
  '_wsj._b8': '個人化',

  '_wsj._cj': '參加',
  '_wsj._gz': '活動規則',
  '_wsj._flss': '分類搜尋',
  '_wsj._wsnz': '萬聖節女裝',
  '_wsj._wsn': '萬聖節男裝',
  '_wsj._wst': '萬聖節童裝',
  '_wsj._wszs': '萬聖節裝飾',
  '_wsj._wslp': '萬聖節禮物',

  '_wsj._desc1': '「萬聖節特惠，不給糖就搗蛋！」 這個十月，我們為你準備了一場狂歡派對！ 來搶購各種恐怖豔驚的商品，大打折扣等你來搶！ ',
  '_wsj._lkm': '去購物',
  '_wsj._wsj': '萬聖節',

  '_wsj._wsjjz': '萬聖節即將到來',
  '_wsj._mzbt': '美容降臨日曆',
  '_wsj._mznr': '一年中最美好的時光的倒數計時剛剛到來，降臨節日曆適合每個人。',

  '_chr._tt1': '聖誕節期間購物',
  '_chr._tt2': '聖誕裝飾品',
  '_chr._tt3': '購買所有禮物',
  '_chr._tt4': '購買所有聖誕睡衣',

  '_chr._dt1': '聖誕節前夕和重要的日子一樣神奇。 所以，整理好你的禮物，裝飾好你的大廳，並準備好相配的睡衣，迎接聖誕節的魔力。',

  '_chr._dtt1': '聖誕服裝',
  '_chr._dtt2': '匹配家庭聖誕節',
  '_chr._dtt3': '降臨日曆',
  '_chr._dtt4': '在家過聖誕節',
  '_chr._dtt5': '舒適的商店',
  '_chr._dtt6': '聖誕節的家具',

  '_chr._tre1': '現在流行',
  '_chr._tre2': '用每個人都喜歡的趨勢為您的家增添一點節日樂趣。',
  '_chr._tre3': '薑餅',
  '_chr._tre4': '聖誕布丁',
  '_chr._tre5': '聖誕節貢克斯',

  '_chr._ttre1': '卡片和包裝紙',
  '_chr._ttre2': '玩具與遊戲',
  '_chr._ttre3': '食品和飲料禮物',
  '_chr._ttre4': '襪子填充物',
  '_chr._ttre5': '秘密聖誕老人',
  '_chr._ttre6': '寶寶的第一個聖誕節',
  '_chr._ttre7': '美麗禮物',
  '_chr._ttre8': '高級禮物',
  '_chr._ttre9': '個人化禮物',

  '_sdj._sdnz': '女裝',
  '_sdj._sdn': '男裝',
  '_sdj._sdetlw': '禮物',
  '_sdj._sdzs': '裝飾',
  '_sdj._sdjj': '玩具',

  '_sdj._title1': '圣诞节即将来临',
  '_sdj._desc1': '「聖誕節：給予與感恩的季節。」 今年十二月，我們為你準備了一場亮麗裝飾，溫馨家庭的派對！來搶購各種亮麗溫馨的商品，大打折扣等你來搶！',

  '_ssd._lhpp': '彈性支付',
  '_ssd._lzhq': '來源環球',
  '_ssd._pwyx': '評為優秀',
  '_ssd._qsth': '輕鬆退貨',
  '_ssd._jgppcn': '價格匹配與承諾',
  '_ssd._ztbt': '打造全球一站式購物',
  '_ssd._yzspl': '直接從 8,000 多個獨立品牌購買庫存',
  '_ssd._zcgm': '註冊購買',
  '_ssd._zccs': '註冊出售',

  '_ssd._dlwz': '登入註冊',

  '_ssd._qjrgwc': '全加入購物車',

  '_zddy._gzlb': '关注列表',
  '_zddy._zsersxs': '在shopifyoutlets销售',
  '_zddy._zccwsj': '注册成为商家',
  '_zddy._dlyh': '登录',
  '_zddy._zcyh': '注册',
  '_zddy._xyh': '新用户',
  '_zddy._gwc': '购物车',
  '_zddy._znxx': '消息',
  '_zddy._zshy': '最受歡迎',
  '_zddy._ddl': '订单',
  '_zddy._mysj': '没有数据',
  '_zddy._ckgd': '查看更多',
  '_zddy._tjsp': '推荐商品',
  '_zddy._rmsp': '热门产品、新趋势、更多采购机会',
  '_zddy._xsp': '新商品',
  '_zddy._jlzzr': '近两周最热商品',
  '_zddy._ljgm': '立即购买',
  '_zddy._gdsp': '更多热门商品',
  '_zddy._ssbt': '搜索',
  '_zddy._ssgjz': '我在找..',

  '_zddy._bdhb': '本地货币',
  '_zddy._xzbdhb': '选择本地货币',
  '_zddy._zje': '总金额',
  '_zddy._xzzffs': '请选择适合您的支付方式',
  '_zddy._jmhb': '加密货币',

  '_zddy._cp': '產品',
  '_zddy._sj': '商家',
  '_zddy._mj': '賣家',
  '_zddy._fhsy': '返回首頁',

  '_sjsy._ddpl': '订单列表',

  '_yhqd._czbz': '儲值前請聯絡專人客服確認儲值資訊',
  '_yhqd._lxkf': '聯絡客服',
  '_yhqd._qsrje': '請輸入儲值金額',
  '_yhqd._wrhyhtd': '無任何銀行通道',
  '_yhqd._yhzz': '銀行轉帳',

  '_yhbt._yhlx': '銀行類型',
  '_zcyz._sjdxyz': '手機簡訊驗證',
  '_zcyz._sjhm': '手機號碼',
  '_zcyz._srsjhm': '輸入手機號碼',
  '_zzsd._qsrzzje': '請輸入轉帳金額',
  '_zzsd._zzje': '轉帳金額',

  '_txcd._qsrtxje': '請輸入提現金額',
  '_txcd._skr': '收款人',
  '_txcd._skr._ms': '請輸入持卡人名稱',
  '_txcd._skzh': '收款帳號',
  '_txcd._skzh._ms': '請輸入收款帳號',
  '_txcd._tjyhk': '新增銀行卡',
  '_txcd._txje': '提現金額',
  '_txcd._wdyhdz': '銀行地址',
  '_txcd._yhdm': '程式碼',
  '_txcd._yhdm._ms': '請輸入銀行代碼',
  '_txcd._yhdz': '地址',
  '_txcd._yhdz._ms': '請輸入銀行地址',
  '_txcd._yhlx': '類型',
  '_txcd._yhlx._ms': '請輸入銀行卡類型',
  '_txcd._yhly': '路由',
  '_txcd._yhly._ms': '請輸入銀行路由',
  '_txcd._yhmc': '銀行',
  '_txcd._yhmc._ms': '請輸入銀行名稱',
  '_txcd._yhtx': '銀行提現',

  '_jys._gmjmhb': '購買加密貨幣',
  '_jys._gmzy': '您可以前往以下交易所，根據您當前國家的政策，註冊帳號併購買加密貨幣。 然後儲值到平台帳戶！ ',

  '_kdydj._bt': '开店赢大奖',
  '_kdydj._xyzbt': '幸运转不停',
  '_kdydj._yx': '抽{n}次',
  '_kdydj._xh': '消耗{n}积分',

  '_kdydj._jf': '积分:',
  '_kdydj._zjjl': '中奖记录',

  '_kdydj._hdgz': '活动规则',
  '_kdydj._qx': '请',
  '_kdydj._dl': '登录',

  '_kdydj._mjdl': '卖家登录',
  '_kdydj._yxh': '邮箱',
  '_kdydj._yxhsm': '请输入邮箱',
  '_kdydj._yxhmm': '请输入密码',
  '_kdydj._rgmyzh': '如果您没有账号',
  '_kdydj._djzc': '点击注册',
  '_kdydj._wjmm': '忘记密码',

  '_kdydj._wdjp': '我的奖品',
  '_kdydj._zjts': '中奖提示',
  '_kdydj._jx': '继续',
  '_kdydj._czl': '抽中了',

  '_kdydj._ljjl': '累計獎勵',
  '_kdydj._ycy': '已參與: {n}次',
  '_kdydj._cy': '參與{n}次',
  '_kdydj._lq': '領取',
  '_kdydj._ylq': '已領取',
  '_kdydj._wdd': '未達到',

  '_dk._dkbt1': '創業貸款 解決借錢的煩惱',
  '_dk._dkbt2': '提供創業貸款 資金週轉服務',
  '_dk._dkbt3': '為你解決資金緊張，無處借錢，不好意思向朋友開口借錢等貸款難題，全程專業人員服務，隨時解答你的疑惑不解，我們的目標是讓每一筆貸款都透明化，讓客戶快速放心的使用。 ',
  '_dk._zxsq': '線上申請',
  '_dk._wddk': '我的貸款',
  '_dk._aqwy': '安全無憂',
  '_dk._lhed': '靈活額度',
  '_dk._lhedb': '額度: 5,00至500,000，利息低 還款方式靈活，無任何前期費用，線上放款，不打審核電話，資訊保密， 當天放款。 ',
  '_dk._jsfk': '急速放款',
  '_dk._jsfkb': '最快一小時通過審核，審核透過立即下款。 ',
  '_dk._aqww1': '安全無憂',
  '_dk._aqww1b': '無抵押貸款，無需押金，不打回訪電話， 這本就是一件私密的事。 ',

  '_dk._gywm': '關於我們',
  '_dk._gywm1': '我們致力於貸款金融服務，基於「客戶至上」的經營理念，以解決客戶資金週轉問題為使命，公司從成立至今，已幫助無數留客戶解決資金週轉問題。 ',
  '_dk._gywm2': '額度: 500至500,000，利息低 還款方式靈活，無任何前期費用，線上放款，不打審核電話，資訊保密， 當天放款。 ',
  '_dk._gywm3': '不想向同學朋友開口借錢，請找我們。 ',
  '_dk._gywm4': '致力於創業家貸款服務',
  '_dk._gywm5': '解決您的資金困擾',
  '_dk._hzhb': '合作夥伴 - 線上借貸平台',
  '_dk._smwda': 'shopifyoutlets Business全球站的使用者來自全球112個國家，使用加密貨幣USDT/ETH/BTC/TRX進行結算。加密貨幣是一種無國界的交易方式，可以在全球範圍內實現即時低成本交易，無需等待，沒有國際費用。 ',

  '_dk._cyzjsq': '創業貸款申請填寫',
  '_dk._dkll': '貸款利率',
  '_dk._dkll2': '貸款利率說明 (點選顯示)',
  '_dk._gs1': '公式',
  '_dk._gs2': '利息',
  '_dk._gs3': '貸款金額',
  '_dk._gs4': '年利率',
  '_dk._gs5': '貸款天數',
  '_dk._gs6': '例如',
  '_dk._gs7': '貸款金額',
  '_dk._gs8': '貸款週期',
  '_dk._gs9': '天',
  '_dk._fkfs': '放款方式',
  '_dk._zhye': '帳戶餘額',
  '_dk._dkqx': '貸款期間',
  '_dk._dkqx1': '選擇貸款期間',
  '_dk._sqje': '申請金額',
  '_dk._qsrdk': '請輸入貸款',
  '_dk._dkje': '貸款金額',
  '_dk._gj': '國籍',
  '_dk._qxzgj': '請選擇國籍',
  '_dk._sqr': '申請人',
  '_dk._qsrsqsm': '請輸入申請人姓名',
  '_dk._zjhm': '證件號碼',
  '_dk._qsrzjhm': '請輸入證件號碼',
  '_dk._zjzp': '證件照',
  '_dk._zjzp1': '請上傳證件正面照片',
  '_dk._zjfm1': '請上證件反面照片',
  '_dk._zjsc1': '請上證件手持照片',
  '_dk._zjzp2': '選擇圖片',
  '_dk._pzslt': '拍照範例',
  '_dk._wytyb': '我已閱讀並同意',
  '_dk._jkxy': '借款協議',
  '_dk._tjsq': '提交申請',

  '_dk._wddk2': '我的貸款',
  '_dk._ydk': '已借款',
  '_dk._cslx': '產生利息',
  '_dk._yjhk': '預計還款',
  '_dk._dkjl': '貸款記錄',
  '_dk._hkjl': '還款記錄',
  '_dk._sqrq': '申請日期',
  '_dk._hkzq': '還款週期',
  '_dk._gdll': '固定利率',
  '_dk._hkz': '還款中',
  '_dk._yhk': '已還款',
  '_dk._sqz': '申請中',
  '_dk._ytg': '已通過',
  '_dk._ybh': '已駁回',
  '_dk._tqhk': '提前還款',

  '_dk._ljhk': '立即還款',
  '_dk._qrytqhk': '確認要提前申請還款嗎',
  '_dk._qrytqhk1': '說明:申請成功後，透過存幣的金額將會直接優先歸還貸款',
  '_dk._dkxq': '貸款詳情',
  '_dk._dkje1': '貸款金額',
  '_dk._yhlx': '應還利息',
  '_dk._hkze': '還款總額',
  '_dk._sqhkz': '申請還款中',
  '_dk._zxydbty': '請仔細閱讀並勾選同意「借款協議」',
  '_dk._zzsqz': '申請中',

  '_dk._dksq': '信貸業務',

  '_dk._cs': '參數',
  '_dk._nllsm': '以百分比表示，例如：6.25%',
  '_dk._dktssm': '從貸款通過開始到結束的總天數',
  '_dk._yndts': '一年的天數： 通常取365天',
  '_dk._zlj': '滯納金',

  '_xbb._xz': '下載',
  '_xbb._xzapp': '下載 ShopifyOutlets 應用程式',
  '_xbb._smewm': '掃描二維碼下載',
  '_xbb._syfl': '所有類別',
  '_xbb._gdfl': '更多',
  '_xbb._sbbt1': '快速送貨',
  '_xbb._sbbt2': '如有任何問題均可退款',
  '_xbb._sbbt3': '免費退貨',
  '_xbb._sbbt4': '90 天內',
  '_xbb._tjsp1': '推薦商品',
  '_xbb._tjsp2': '超多優惠商品',
  '_xbb._tjsp3': '限時折扣',

  '_xbb._asjgw': '按商店購物',
  '_xbb._gdsp': '更多商品',
  '_xbb._ljgm': '立即購買',
  '_xbb._ptbz': 'ShopifyOutlets 保證',
  '_xbb._aqzf': '安全支付',
  '_xbb._aqzf1': '許多國際購物者使用的付款方式',
  '_xbb._aqys': '安全與隱私',
  '_xbb._aqys1': '我們尊重您的隱私，因此您的個人資訊是安全的',
  '_xbb._mjbh': '買家保護',
  '_xbb._mjbh1': '如果您的訂單未在預計日期前送達或您對訂單不滿意，可退款',
  '_xbb._mzsm': '警告/免責聲明',

  '_xbb._sjxx': '商家',
  '_xbb._ggms': '規格',
  '_xbb._mswa': '描述',
  '_xbb._khpl': '顧客評論',
  '_xbb._zmfk': '正面回饋',
  '_xbb._gzrs': '關注',
  '_xbb._gzrsan': '關注',
  '_xbb._ygzrsan': '已關注',
  '_xbb._fsxx': '訊息',
  '_xbb._myrpl': '暫時沒有任何人評論',
  '_xbb._llgd': '瀏覽更多',
  '_xbb._pdcl': '承諾',
  '_xbb._myf': '免運費',
  '_xbb._sdsj': '大約5-10天送達',
  '_xbb._ksjh': '快速交貨',
  '_xbb._bgdstk': '包裹遺失退款',
  '_xbb._shbgtk': '如果物品損壞，退款',
  '_xbb._s30gtk': '30 天內未出貨可退款',
  '_xbb._aqyys': '安全與隱私',
  '_xbb._wjtrxk': '安全付款：未經您的同意，我們不會與任何第三方分享您的個人資訊。 ',
  '_xbb._sfgrxx': '安全的個人詳細資料：我們保護您的隱私並確保您的個人詳細資料安全無虞。 ',
  '_xbb._sl': '數量',
  '_xbb._kcld': '庫存',

  '_xbb._sjrz': '商家入駐',
  '_xbb._hezjh': '我們的合作夥伴計畫為您提供全方位的行銷支援和服務，我們的客戶服務團隊將提供專業的支援和建議，幫助您優化您的產品展示和銷售策略。現在就加入我們吧！讓我們一起創造更大的商業機會，一起成長！ ',
  '_xbb._xsje': '商業資訊',
  '_xbb._rgsqjs': '如果您已是賣家',
  '_xbb._qni': '請',
  '_xbb._djdl': '點擊登入',
  '_xbb._dplog': '店鋪LOGO',
  '_xbb._dpmc': '商店名稱',
  '_xbb._srdpmc': '請輸入店家名稱',
  '_xbb._zjhm': '證件號碼',
  '_xbb._srzjhm': '請輸入證件號碼',
  '_xbb._zsxm': '真實姓名',
  '_xbb._srzsxm': '請輸入真實姓名',
  '_xbb._srgj': '國',
  '_xbb._srsrgj': '請輸入國家',
  '_xbb._zjz': '證件照',
  '_xbb._zjzm': '證件正面',
  '_xbb._zjfm': '證件反面',
  '_xbb._sczjz': '手持證件照',
  '_xbb._pssl': '拍攝範例',
  '_xbb._dlyx': '登入郵箱',
  '_xbb._srdlyx': '請輸入登入信箱',
  '_xbb._dlmm': '登入密碼',
  '_xbb._srdlmm': '請輸入登入密碼',
  '_xbb._qrmm': '確認密碼',
  '_xbb._srqrmm': '請輸入確認密碼',
  '_xbb._yqm': '邀請碼',
  '_xbb._sryqm': '輸入邀請碼',
  '_xbb._yxyzm': '郵箱驗證碼',
  '_xbb._sryzm': '輸入郵箱驗證碼',
  '_xbb._yydbty': '我已經閱讀並同意',
  '_xbb._rzxy': '入駐協定',

  '_xbb._qgxty': '請仔細閱讀入駐協議並勾選同意',
  '_xbb._sryqmb': '請輸入邀請碼',

  '_xbb._ddpl': '訂單評論',
  '_xbb._dsppl': '對商品評論',
  '_xbb._dddpl': '對訂單評論'
}
